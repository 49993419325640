export const status_enum = {
    is_loading: "is_loading",
    is_completed: "is_completed",
    is_saving: "is_saving",
};
export const ini_state = {
    status: "",
    uf_donhang: {},
    uf_donhangchitiets: [],
    is_show_confirm_finish: false
};
export const action_type = {
    SET_DONHANG: "SET_DONHANG",
    BEGIN_SELECT_DONHANGCHITIETS: "BEGIN_SELECT_DONHANGCHITIETS",
    END_SELECT_DONHANGCHITIETS: "END_SELECT_DONHANGCHITIETS",
    BEGIN_SAVING: "BEGIN_SAVING",
    END_SAVING: "END_SAVING",
    SHOW_CONFIRM_FINISH: "SHOW_CONFIRM_FINISH",
    CLOSE_CONFIRM_FINISH: "CLOSE_CONFIRM_FINISH"

};

export const reducer = (state, action) => {
    switch (action.type) {
        case action_type.BEGIN_SELECT_DONHANGCHITIETS:
            return {
                ...state,
                status: status_enum.is_loading
            };
        case action_type.END_SELECT_DONHANGCHITIETS:
            return {
                ...state,
                status: status_enum.is_completed,
                uf_donhangchitiets: action.payload
            };
        case action_type.SET_DONHANG:
            return {
                ...state,
                uf_donhang: action.payload
            };

        case action_type.BEGIN_SAVING:
            return {
                ...state,
                status: status_enum.is_saving,
            };
        case action_type.END_SAVING:
            return {
                ...state,
                status: status_enum.is_completed,
            };
        case action_type.SHOW_CONFIRM_FINISH:
            return {
                ...state,
                is_show_confirm_finish: true
            };
        case action_type.CLOSE_CONFIRM_FINISH:
            return {
                ...state,
                is_show_confirm_finish: false
            };
        default:
            return state;
    }
};
