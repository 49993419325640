import appInfo from '../app-info';
import { call_post_api } from './api_helper';

export const selct_by_donhang = async (uf_donhang_id) => {
  return call_post_api(appInfo.api_url + '/uf_donhangchitiet/selct_by_donhang', uf_donhang_id);
};
export const insert = async (data) => {
  return call_post_api(
    appInfo.api_url + '/uf_donhangchitiet/insert',
    data
  );
};

export const remove = async (uf_donhangchitiet_id) => {
  return call_post_api(
    appInfo.api_url + '/uf_donhangchitiet/delete',
    uf_donhangchitiet_id
  );
};

export const doitra = async (uf_donhangchitiets) => {
  return call_post_api(
    appInfo.api_url + '/uf_donhangchitiet/doitra',
    uf_donhangchitiets
  );
}
