import React, { useState, useRef, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../contexts/auth";
import app_info from "../../app-info";
import "./login-form.scss";
import { Image } from "semantic-ui-react";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function () {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const query = useQuery();
  const hash = query.get("hash");
  const ticket_key = query.get("key");
  const { signInSSO } = useAuth();
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { username, password } = formData.current;
      setLoading(true);
      const result = await signIn(username, password);
      if (!result.is_success) {
        setLoading(false);
        notify(result.message, "error", 2000);
        console.log(result.message);
      }
    },
    [signIn]
  );

  useEffect(() => {
    (async function () {
      const res = await signInSSO(ticket_key, hash);
      if (!res) {
        window.location.href = app_info.home_url;
      }
    })();
  }, [ticket_key]);
  return (
    <React.Fragment>
      <Image src="./images/3s.png" className="loading-login"></Image>
    </React.Fragment>
    // <form className={'login-form'} onSubmit={onSubmit}>
    //   <Form formData={formData.current} disabled={loading}>
    //     <Item
    //       dataField={'username'}
    //       editorType={'dxTextBox'}
    //       editorOptions={usernameEditorOptions}
    //     >
    //       <RequiredRule message="Vui lòng điền họ tên" />
    //       <Label visible={false} />
    //     </Item>
    //     <Item
    //       dataField={'password'}
    //       editorType={'dxTextBox'}
    //       editorOptions={passwordEditorOptions}
    //     >
    //       <RequiredRule message="Vui lòng điền mật khẩu" />
    //       <Label visible={false} />
    //     </Item>

    //     <ButtonItem>

    //       <ButtonOptions
    //         width={'100%'}
    //         type={'default'}
    //         useSubmitBehavior={true}
    //       >
    //         <span className="dx-button-text">
    //           {
    //             loading
    //               ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
    //               : 'Đăng nhập'
    //           }
    //         </span>

    //       </ButtonOptions>
    //     </ButtonItem>
    //   </Form>
    // </form>
  );
}

const usernameEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Username",
  mode: "text",
};
const emailEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Email",
  mode: "email",
};
const passwordEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Password",
  mode: "password",
};
const rememberMeEditorOptions = {
  text: "Remember me",
  elementAttr: { class: "form-text" },
};
