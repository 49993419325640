export const status_enum = {
  is_loading: "is_loading",
  is_completed: "is_completed",
  is_saving: "is_saving",
};
export const ini_state = {
  sf_hinhthucthanhtoans: [],
  uf_donhang_hinhthucthanhtoans: [],
  uf_donhang: {},
  uf_hocsinh_congno: null,
  status: ""
};
export const action_type = {
  SET_DONHANG: "SET_DONHANG",
  SET_DM_HINHTHUCs: "SET_DM_HINHTHUCs",
  BEGIN_SELECT_HINHTHUCTHANHTOANS: "BEGIN_SELECT_HINHTHUCTHANHTOANS",
  END_SELECT_HINHTHUCTHANHTOANS: "END_SELECT_HINHTHUCTHANHTOANS",
  BEGIN_SAVING: "BEGIN_SAVING",
  END_SAVING: "END_SAVING",
  SET_HOCSINH_CONGNO: "SET_HOCSINH_CONGNO"
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.SET_DONHANG:
      return {
        ...state,
        uf_donhang: action.payload,
      };
    case action_type.BEGIN_SELECT_HINHTHUCTHANHTOANS:
      return {
        ...state,
        status: status_enum.is_loading
      };
    case action_type.SET_DM_HINHTHUCs:
      return {
        ...state,
        status: status_enum.is_completed,
        sf_hinhthucthanhtoans: action.payload
      };
    case action_type.END_SELECT_HINHTHUCTHANHTOANS:
      return {
        ...state,
        status: status_enum.is_completed,
        uf_donhang_hinhthucthanhtoans: action.payload
      };
    case action_type.BEGIN_SAVING:
      return {
        ...state,
        status: status_enum.is_saving,
      };
    case action_type.END_SAVING:
      return {
        ...state,
        status: status_enum.is_completed,
      };
    case action_type.SET_HOCSINH_CONGNO:
      return {
        ...state,
        uf_hocsinh_congno: action.payload
      };
    default:
      return state;
  }
};
