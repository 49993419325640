import DataGrid, {
  Column, FilterPanel, FilterRow,
  HeaderFilter, Pager,
  Paging,
  Selection, Sorting
} from "devextreme-react/data-grid";
import Toolbar, { Item } from "devextreme-react/toolbar";
import React, {
  useCallback,
  useContext, useEffect, useReducer, useRef, useState
} from "react";
import { Transition, Modal } from "semantic-ui-react";
import { select_by_hocsinh, print_phieuchi } from "../../api/uf_donhang";
import { DefaultButton, PrimaryButton } from "../../components/editor/button";
import { ComboboxKho } from "../../components/editor/combobox";
import StudentSearch from "../../components/editor/ts_hocsinh_search";
import { StoreContext } from "../../contexts/store";
import DetailModal from "./detail_modal/detail_modal";
import { action_type, ini_state, reducer, status_enum } from "./reducer";
import DoiTraModal from "./doitra_modal/doitra_modal"
import PHieuChiModal from "./phieuchi_modal/phieuchi_modal"

export default () => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const grid = useRef(null);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const [print_url, set_print_url] = useState("");
  const [is_printing, set_is_printing] = useState(false);
  const [is_show_modal_print, set_is_show_modal_print] = useState(false);
  const {
    status,
    orders,
    order_selected,
    is_show_detail_modal,
    is_show_doitra_modal,
    is_show_phieuchi_modal,
    ts_hocsinh,
    uf_kho,
  } = state;
  useEffect(() => {
    if (status == status_enum.is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [status]);

  useEffect(() => {
    console.log(ts_hocsinh)
    reload_donhang();
  }, [ts_hocsinh]);
  const reload_donhang = async () => {
    const res = await select_by_hocsinh(ts_hocsinh.id);
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_ORDERS, payload: res.data.map(x => {
          return { ...x, thua_thieu: x.thuc_nop - x.phai_nop }
        })
      });
    } else {
      dispatch({ type: action_type.END_SELECT_ORDERS, payload: [] });
    }
  };
  const onStudentChanged = useCallback((data) => {
    dispatch({ type: action_type.CHANGE_HOCSINH, payload: data });
  }, []);

  const btnCancel_click = () => {
    reload_donhang();
    dispatch({ type: action_type.CLOSE_DETAIL_MODAL });
  };
  const btnCancelDoiTra_click = () => {
    reload_donhang();
    dispatch({ type: action_type.CLOSE_DOITRA_MODAL });
  };
  const btnAdd_click = useCallback(() => {
    dispatch({ type: action_type.SHOW_DETAIL_MODAL, payload: { id: 0 } });
  }, []);
  const btnEdit_click = (data) => {
    dispatch({ type: action_type.SHOW_DETAIL_MODAL, payload: data });
  };

  const bntDoiTra_click = (data) => {
    dispatch({ type: action_type.SHOW_DOITRA_MODAL, payload: data });
  };
  const bntPhieuChi_click = (data) => {
    dispatch({ type: action_type.SHOW_PHIEUCHI_MODAL, payload: data });
  };
  const bntPhieuChiClose_click = (data) => {
    dispatch({ type: action_type.CLOSE_PHIEUCHI_MODAL });
  };
  const onPhieuChiSuccess = () => {
    reload_donhang();
    dispatch({ type: action_type.CLOSE_PHIEUCHI_MODAL });
  }
  const handlePrintPhieuChi = async (data) => {
    set_is_printing(true);
    const res = await print_phieuchi(data.uf_phieuchi_id);
    if (res.is_success) {
      set_is_printing(false);
      set_print_url(res.message);
      set_is_show_modal_print(true);

    } else {
      set_print_url("");
    }

  }
  const commandCellRender = (data) => {
    return (
      <React.Fragment>
        <span
          style={{ cursor: "pointer" }}
          className="text-success"
          onClick={() => {
            btnEdit_click(data.data);
          }}
        >
          <i aria-hidden="true" className="fas fa-info-circle"></i>
          {" Chi tiết"}
        </span>
        {data.data.uf_trangthaidonhang_id == 4 && can_accessed("api/uf_donhangchitiet/doitra") && data.data.ma_don_hang_goc == "" &&
          <span
            style={{ cursor: "pointer" }}
            className="text-danger margin-left-5"
            onClick={() => {
              bntDoiTra_click(data.data);
            }}
          >
            <i aria-hidden="true" className="fas fa-undo"></i>
            {" Đổi trả"}
          </span>
        }
        {data.data.thuc_nop > data.data.phai_nop && data.data.uf_phieuchi_id == 0 &&
          <span
            style={{ cursor: "pointer" }}
            className="text-danger margin-left-5"
            onClick={() => {
              bntPhieuChi_click(data.data);
            }}
          >
            <i aria-hidden="true" className="fas fa-file"></i>
            {" Tạo phiếu chi"}
          </span>}
        {data.data.uf_phieuchi_id > 0 &&
          <span
            style={{ cursor: "pointer" }}
            className="text-info margin-left-5"
            onClick={() => {
              handlePrintPhieuChi(data.data);
            }}
          >
            <i aria-hidden="true" className="fas fa-file"></i>
            {" In phiếu chi"}
          </span>}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Transition
        animation="fade right"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <div className="row">
            <div className="col-md-12 padding-top-1rem padding-left-2rem">
              <Toolbar className="bg-background">
                <Item
                  location="before"
                  locateInMenu="never"
                  render={() => {
                    return <h3>Đơn hàng</h3>;
                  }}
                />
                <Item
                  location="center"
                  locateInMenu="never"
                  render={() => {
                    return <StudentSearch onResultChanged={onStudentChanged} />;
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <ComboboxKho
                        value={uf_kho ? uf_kho.id : undefined}
                        onValueChanged={(value, uf_kho) => {
                          dispatch({
                            type: action_type.CHANGE_KHO,
                            payload: uf_kho,
                          });
                        }}
                      />
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      can_accessed("api/uf_donhangchitiet/insert") && (
                        <PrimaryButton
                          text={"Thêm đơn hàng"}
                          icon="fas fa-plus"
                          disabled={
                            ts_hocsinh.id == undefined || uf_kho.id == undefined
                          }
                          onClick={btnAdd_click}
                        />
                      )
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <DefaultButton
                        text={"Export"}
                        icon="fas fa-file-export"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      />
                    );
                  }}
                />
              </Toolbar>
            </div>
          </div>
          <DataGrid
            ref={grid}
            keyExpr="id"
            dataSource={orders}
            columnHidingEnabled={false}
            columnAutoWidth={true}
            showBorders={true}
            showColumnLines={false}
            showRowLines={true}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            hoverStateEnabled={true}
            focusedRowEnabled={false}
            height={window.innerHeight - 150}
            className="table-noborder-left"
            noDataText="Không có dữ liệu"
          >
            <Selection mode="single" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={50} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <HeaderFilter visible={true} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50, 200, 500]}
              infoText="Trang {0} /{1} ({2} dòng)"
              showInfo={true}
            />
            <Column caption="Thao tác" cellRender={commandCellRender} />
            <Column dataField="ho_ten" caption="Tên học sinh" />
            <Column dataField="trang_thai" caption="Trạng thái" />
            <Column dataField="ma_don_hang" caption="Mã đơn hàng" />
            <Column dataField="ma_don_hang_goc" caption="Đơn hàng gốc" />
            <Column caption="Số tiền" alignment="center">
              <Column dataField="thanh_tien" caption="Tổng tiền" format=",##0" />
              <Column dataField="phai_nop" caption="Phải nộp" format=",##0" />
              <Column dataField="thuc_nop" caption="Đã nộp" format=",##0" />
              <Column dataField="thua_thieu" caption="Thừa/thiếu" format=",##0" />
              <Column dataField="hoan_tra" caption="Đã hoàn trả" format=",##0" />
              <Column
                dataField="ngay_thu_tien"
                caption="Ngày thu"
                dataType="datetime"
                format="dd/MM/yy HH:mm"
              />
            </Column>
            <Column dataField="nguoi_tao" caption="Người tạo" />
            <Column
              dataField="ngay_tao_phieu"
              caption="Thời gian tạo"
              dataType="datetime"
              format="dd/MM/yy HH:mm"
            />
            {/* <Column
              dataField="ghi_chu"
              caption="Ghi chú"
              allowFiltering={false}
            /> */}
          </DataGrid>
        </div>
      </Transition>

      {is_show_detail_modal && (
        <DetailModal
          onCancel={btnCancel_click}
          uf_donhang_id={order_selected.id}
          ts_hocsinh={ts_hocsinh}
          uf_kho={uf_kho}
        />
      )}
      {is_show_doitra_modal && (
        <DoiTraModal
          onCancel={btnCancelDoiTra_click}
          uf_donhang_id={order_selected.id}
          ts_hocsinh={ts_hocsinh}
          uf_kho={uf_kho}
        />
      )}
      {is_show_phieuchi_modal && (
        <PHieuChiModal
          onCancel={bntPhieuChiClose_click}
          onSuccess={onPhieuChiSuccess}
          uf_donhang_id={order_selected.id}
          uf_phieuchi_id={order_selected.uf_phieuchi_id}
        />
      )}
      {
        is_show_modal_print &&
        <Modal open={is_show_modal_print} size="large" dimmer={"blurring"} onClose={() => { set_is_show_modal_print(false) }}
          closeOnEscape={false}
          height={window.innerHeight - 250}
          closeOnDimmerClick={false}>
          <Modal.Header>

          </Modal.Header>
          <Modal.Content>
            <div id="embed_print_bienlai">
              <embed id="embed_print_bienlai" src={print_url} width='100%' height={window.innerHeight - 150} type="application/pdf"></embed>
            </div>

          </Modal.Content>
          <Modal.Actions>

            <div>
              <button onClick={() => { set_is_show_modal_print(false) }} className="p-btn p-btn-sm">
                Đóng
              </button>


            </div>
          </Modal.Actions>
        </Modal >
      }
    </React.Fragment>
  );
};
