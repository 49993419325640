import React from "react";
import { Search, Label } from "semantic-ui-react";
import { search } from "../../api/ts_hocsinh";
import "./ts_hocsinh_search.css";
const initialState = {
  loading: false,
  results: [],
  value: "",
};

const search_reducer = (state, action) => {
  switch (action.type) {
    case "CLEAN_QUERY":
      return initialState;
    case "START_SEARCH":
      return { ...state, loading: true, value: action.query };
    case "FINISH_SEARCH":
      return { ...state, loading: false, results: action.results };
    case "UPDATE_SELECTION":
      return { ...state, value: action.selection };

    default:
      throw new Error();
  }
};

export default ({ dm_truong_id, onResultChanged }) => {
  const [state, dispatch] = React.useReducer(search_reducer, initialState);
  const { loading, results, value } = state;

  const timeoutRef = React.useRef();
  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);
      dispatch({ type: "START_SEARCH", query: data.value });

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch({ type: "CLEAN_QUERY" });
          return;
        }
        (async () => {
          const res = await search({
            dm_truong_id: dm_truong_id,
            key: data.value,
          });
          if (res.is_success) {
            dispatch({
              type: "FINISH_SEARCH",
              results: res.data.map((x) => {
                return {
                  title:
                    (x.ma_hs !== "" || x.ma_tn !== ""
                      ? "[" + (x.ma_hs !== "" ? x.ma_hs : x.ma_tn) + "] - "
                      : "") + x.ho_ten,
                  ho_ten: x.ho_ten,
                  ma_hs: x.ma_hs,
                  id: x.id,
                  ngay_sinh: x.ngay_sinh,
                  email: x.email_lien_he,
                  dien_thoai: x.dien_thoai_lien_he,
                  ten_khoi: x.ten_khoi,
                  ten_he: x.ten_he,
                  ten_truong: x.ten_truong,
                  ma_tn: x.ma_tn,
                };
              }),
            });
          }
        })();
      }, 500);
    },
    [dm_truong_id]
  );
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  React.useEffect(() => {
    dispatch({ type: "CLEAN_QUERY" });
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [dm_truong_id]);
  const resultRenderer = (hocsinh) => {
    return (
      <React.Fragment>
        <div className="content">
          <div className="price">
            {hocsinh.ma_hs ? hocsinh.ma_hs : hocsinh.ma_tn}
          </div>
          <div className="title">{hocsinh.ho_ten}</div>
          <div className="description">
            {hocsinh.ten_he} - {hocsinh.ten_khoi}
          </div>
          <div className="description">{hocsinh.dien_thoai}</div>
          <div className="description">{hocsinh.email}</div>
        </div>
      </React.Fragment>
    );
  };
  return (
    <Search
      loading={loading}
      onResultSelect={(e, data) => {
        dispatch({ type: "UPDATE_SELECTION", selection: data.result.title });
        onResultChanged(data.result);
      }}
      onSearchChange={handleSearchChange}
      results={results}
      resultRenderer={resultRenderer}
      value={value}
      input={{ icon: "search", placeholder: "Tìm theo mã hoặc họ tên" }}
      fluid
      className="student_search"
    ></Search>
  );
};
