import DataGrid, {
  Column,
  Editing, FilterRow, Pager,
  Paging, Sorting
} from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import React, {
  useCallback,
  useContext, useEffect, useMemo, useReducer, useRef, useState
} from "react";
import { Modal } from "semantic-ui-react";
import { select_by_id as uf_donhang_select_by_id, print, print_khongthuphi } from "../../../api/uf_donhang";
import { select_all as uf_settieuchuan_select_all } from "../../../api/uf_settieuchuan";
import { select_all as uf_settieuchuan_sanpham_select_all } from "../../../api/uf_settieuchuan_sanpham";
import {
  insert as uf_donhangchitiet_insert,
  selct_by_donhang as uf_donhangchitiet_selct_by_donhang
} from "../../../api/uf_donhangchitiet";
import { select_all as uf_sanphamchitiet_select_all } from "../../../api/uf_sanphamchitiet";
import { select_by_donhang as uf_donhang_hinhthucthanhtoan_select_by_donhang } from "../../../api/uf_donhang_hinhthucthanhtoan";
import {
  DefaultButton, PrimaryButton
} from "../../../components/editor/button";
import { StoreContext } from "../../../contexts/store";
import NopTienModal from "../noptien_modal/noptien_modal";
import "./detail_modal.css";
import { action_type, ini_state, reducer } from "./reducer";
import RowInfo from "./rowInfo";
import { SelectBox } from 'devextreme-react/select-box'
export default ({ ts_hocsinh, uf_kho, uf_donhang_id, onCancel }) => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const grid_product = useRef(null);
  const grid_order = useRef(null);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const [print_url, set_print_url] = useState("");
  const [is_printing, set_is_printing] = useState(false);
  const [is_show_modal_print, set_is_show_modal_print] = useState(false);
  const {
    is_loading_sanphamchitiets,
    is_loading_donhangchitiets,
    uf_sanphamchitiets,
    uf_donhang,
    uf_donhangchitiets,
    status,
    uf_donhang_hinhthucthanhtoans,
    is_show_confirm_noptien,
    uf_settieuchuans,
    uf_settieuchuan_selected,
    uf_settieuchuan_sanphams
  } = state;
  const uf_sanphams = useMemo(() => {

    let result = [];
    let uf_sanphamchitiet_filters = uf_sanphamchitiets;
    if (uf_settieuchuan_selected && uf_settieuchuan_selected.id > 0) {
      const uf_sanpham_ids = uf_settieuchuan_sanphams.filter(x => x.uf_settieuchuan_id == uf_settieuchuan_selected.id).map(x => x.uf_sanpham_id);
      uf_sanphamchitiet_filters = uf_sanphamchitiet_filters.filter(x => uf_sanpham_ids.includes(x.uf_sanpham_id));
    }

    uf_sanphamchitiet_filters.forEach((uf_sanphamchitiet) => {
      if (
        result.filter((x) => x.id == uf_sanphamchitiet.uf_sanpham_id).length <=
        0
      ) {
        result.push({
          id: uf_sanphamchitiet.uf_sanpham_id,
          ma_sp: uf_sanphamchitiet.ma_sp,
          ten_sp: uf_sanphamchitiet.ten_sp,
        });
      }
    });
    return result;
  }, [uf_sanphamchitiets, uf_settieuchuan_selected]);
  useEffect(() => {
    if (uf_donhang_id > 0) {
      (async () => {
        const res = await uf_donhang_select_by_id(uf_donhang_id);
        if (res.is_success) {
          dispatch({ type: action_type.SET_DONHANGS, payload: res.data });
        }
      })();
    }
  }, [uf_donhang_id]);
  useEffect(() => {
    if (is_loading_donhangchitiets) {
      grid_order.current.instance.beginCustomLoading();
    } else {
      grid_order.current.instance.endCustomLoading();
    }
  }, [is_loading_donhangchitiets]);

  useEffect(() => {
    if (is_loading_sanphamchitiets) {
      grid_product.current.instance.beginCustomLoading();
    } else {
      grid_product.current.instance.endCustomLoading();
    }
  }, [is_loading_sanphamchitiets]);
  useEffect(() => {
    reload_settieuchuans();
    reload_settieuchuan_sanphams();
    reaload_sanphamchitiets();
  }, []);
  useEffect(() => {
    if (uf_donhang.id > 0) {
      reload_donhangchitiets();
      reload_donhang_hinhthucthanhtoans();
    }
  }, [uf_donhang]);
  useEffect(() => {
    if (uf_donhang.id > 0) {
      if (uf_donhang.uf_settieuchuan_id > 0) {
        const data = uf_settieuchuans.find(x => x.id == uf_donhang.uf_settieuchuan_id);
        if (data) {
          dispatch({ type: action_type.SET_SETTIEUCHUAN_SELECTED, payload: data })
        }
      }
    }
  }, [uf_donhang, uf_settieuchuans]);
  const reload_donhang_hinhthucthanhtoans = async () => {
    const res = await uf_donhang_hinhthucthanhtoan_select_by_donhang(uf_donhang.id);
    if (res.is_success) {
      dispatch({ type: action_type.SET_DONHANG_HINHTHUCTHANHTOANS, payload: res.data })
    } else {
      dispatch({ type: action_type.SET_DONHANG_HINHTHUCTHANHTOANS, payload: [] })
    }
  }
  const reaload_sanphamchitiets = useCallback(async () => {
    dispatch({ type: action_type.BEGIN_SELECT_SANPHAMCHITIETS });
    const res = await uf_sanphamchitiet_select_all();
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_SANPHAMCHITIETS,
        payload: res.data.map((x) => {
          return {
            ...x,
            is_out_of_stock: x.so_luong_ton <= 0,
            so_luong_ton: 0,
          };
        }),
      });
    }
  }, []);
  const reload_donhangchitiets = async () => {
    dispatch({ type: action_type.BEGIN_SELECT_DONHANGCHITIETS });
    const res = await uf_donhangchitiet_selct_by_donhang(uf_donhang.id);
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_DONHANGCHITIETS,
        payload: res.data,
      });
    }
  };
  const reload_settieuchuans = async () => {
    const res = await uf_settieuchuan_select_all();
    if (res.is_success) {
      dispatch({
        type: action_type.SET_SETTIEUCHUANS,
        payload: res.data,
      });
    }
  };
  const reload_settieuchuan_sanphams = async () => {
    const res = await uf_settieuchuan_sanpham_select_all();
    if (res.is_success) {
      dispatch({
        type: action_type.SET_SETTIEUCHUAN_SANPHAMS,
        payload: res.data,
      });
    }
  };

  const add_item = async (uf_sanphamchitiet, so_luong) => {
    if (uf_donhang.ngay_thu_tien) return false;
    const uf_settieuchuan_id = uf_settieuchuan_selected ? (uf_settieuchuan_selected.id ? uf_settieuchuan_selected.id : 0) : 0;
    
    if (so_luong > 0 && uf_donhangchitiets.find(x => x.uf_settieuchuan_id > 0 && x.uf_settieuchuan_id != uf_settieuchuan_id) != null) {
      set_notification({ type: "waning", text: "Đơn hàng không cho phép tạo sản phẩm ở các set tiêu chuẩn khác nhau. Bạn cần tạo đơn hàng mua thêm và đơn hàng set tiêu chuẩn riêng." });
      return false;
    }
    if (
      uf_donhang.uf_trangthaidonghang_id == 1 ||
      uf_donhang.uf_trangthaidonghang_id == undefined
    ) {
      dispatch({ type: action_type.BEGIN_SAVING });
      const res = await uf_donhangchitiet_insert({
        uf_kho_id: uf_kho.id,
        ma_kho: uf_kho.ma_kho,
        ts_hocsinh_id: ts_hocsinh.id,
        uf_donhang_id: uf_donhang.id,
        uf_sanphamchitiet_id: uf_sanphamchitiet.id,
        so_luong: so_luong ? so_luong : 1,
        uf_dongia_id: uf_sanphamchitiet.uf_dongia_id,
        size: uf_sanphamchitiet.size,
        ten_sp: uf_sanphamchitiet.ten_sp,
        uf_settieuchuan_id: uf_settieuchuan_id
      });
      dispatch({ type: action_type.END_SAVING });
      if (res.is_success) {
        dispatch({ type: action_type.SET_DONHANGS, payload: res.data });
        set_notification({ type: "success", text: "Cập nhật thành công" });
      } else {
        set_notification({ type: "error", text: res.message });
      }
    } else {
      set_notification({
        type: "warning",
        text: "Đơn hàng đã thu tiền, bạn không thể chỉnh sửa",
      });
    }
  };

  const rowRender = (rowInfo) => {
    const size_list = get_size_list(rowInfo.data.id);
    return (
      <RowInfo
        data={rowInfo.data}
        size_list={size_list}
        onAddedItem={(uf_donhang) => {
          dispatch({ type: action_type.SET_DONHANGS, payload: uf_donhang });
        }}
        ts_hocsinh={ts_hocsinh}
        uf_kho={uf_kho}
        uf_donhang={uf_donhang}
        uf_donhangchitiets={uf_donhangchitiets}
        uf_settieuchuan_id={(uf_settieuchuan_selected && uf_settieuchuan_selected.id > 0) ? uf_settieuchuan_selected.id : 0}

      />
    );
  };

  const get_size_list = (uf_sanpham_id) => {
    return uf_sanphamchitiets.filter((x) => x.uf_sanpham_id == uf_sanpham_id);
  };
  const LeftHeader = () => {
    return (
      <table className="width-100-percent min-height-70">
        <tbody>
          <tr>
            <td>
              <SelectBox
                dataSource={uf_settieuchuans}
                displayExpr={"ten_set_tieu_chuan"}
                valueExpr={"id"}
                placeholder="Chọn set tiêu chuẩn (nếu mua theo set)"
                value={uf_settieuchuan_selected ? uf_settieuchuan_selected.id : (uf_donhang ? uf_donhang.uf_settieuchuan_id : 0)}
                stylingMode="outlined"
                showClearButton={true}
                onValueChanged={(e) => {

                  const obj = e.value ? uf_settieuchuans.find(x => x.id == e.value) : null;
                  dispatch({ type: action_type.SET_SETTIEUCHUAN_SELECTED, payload: obj });

                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ marginBottom: 1 }}><i>*Double click vào size của mỗi sản phẩm để thêm vào đơn hàng</i></p>
            </td>
          </tr>


        </tbody>
      </table>
    );
  };
  const btnDelete_click = (data) => {
    add_item(
      {
        id: data.uf_sanphamchitiet_id,
        uf_dongia_id: data.uf_dongia_id,
        size: data.size,
        ten_sp: data.ten_sp,
      },
      -1 * data.so_luong
    );
  }
  const editCellRender = (cell) => {
    const data = cell.data;
    return (
      <NumberBox
        stylingMode="underlined"
        defaultValue={data.so_luong}
        min={0}
        readOnly={uf_donhang.ngay_thu_tien}
        showClearButton={true}
        onValueChanged={(e) => {
          add_item(
            {
              id: data.uf_sanphamchitiet_id,
              uf_dongia_id: data.uf_dongia_id,
              size: data.size,
              ten_sp: data.ten_sp,
            },
            e.value - e.previousValue
          );
        }}
      />
    );
  };

  const handlePrintKhongThuPhi = async () => {
    set_is_printing(true);
    const res = await print_khongthuphi(uf_donhang.id);
    if (res.is_success) {
      set_is_printing(false);
      set_print_url(res.message);
      set_is_show_modal_print(true);

    } else {
      set_print_url("");
    }

  }
  const handlePrint = async () => {
    set_is_printing(true);
    if (uf_donhang.uf_settieuchuan_id > 0) {
      await handlePrintKhongThuPhi();
      return true;
    } else {


      const res = await print(uf_donhang.id);
      if (res.is_success) {
        set_is_printing(false);
        set_print_url(res.message);
        set_is_show_modal_print(true);

      } else {
        set_print_url("");
      }
    }

  }
  return (
    <React.Fragment>
      <Modal
        open={true}
        size="large"
        dimmer={"blurring"}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <table className="width-100-percent min-height-70">
            <tbody>
              <tr>
                <td>
                  <h5 className="text-info margin-bottom-0"><b>{ts_hocsinh.ho_ten}</b></h5>
                  <p className="margin-bottom-0">
                    *Mã đơn hàng:{" "}
                    <b className="text-info">{uf_donhang.ma_don_hang}</b>

                  </p>
                </td>
                <td className="text-align-right">
                  {can_accessed("api/uf_donhang/pay") &&
                    <React.Fragment>
                      <PrimaryButton
                        text="Thu tiền"
                        icon="fas fa-check"
                        className="margin-bottom-0"
                        onClick={() => {
                          dispatch({ type: action_type.SHOW_CONFIRM_NOPTIEN });
                        }}
                        disabled={!uf_donhang.id > 0 || uf_donhang.ngay_thu_tien || !uf_donhangchitiets || uf_donhangchitiets.length <= 0}
                      />
                      <DefaultButton
                        text="In phiếu"
                        icon="fas fa-print"
                        className="margin-bottom-0 margin-right-0"
                        disabled={!uf_donhang.id > 0 || is_printing}
                        isLoading={is_printing}
                        onClick={() => {
                          handlePrint();
                        }}
                      />
                      {/* <DefaultButton
                      text="In mẫu KHÔNG có đơn giá"
                      icon="fas fa-print"
                      className="margin-bottom-0 margin-right-0"
                      disabled={!uf_donhang.id > 0 || is_printing}
                      isLoading={is_printing}
                      onClick={() => {
                        handlePrintKhongThuPhi();
                      }}
                    /> */}
                    </React.Fragment>
                  }


                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Header>
        <Modal.Content>
          <div className="flex-container">
            <div className="list-left">
              <LeftHeader />
              <DataGrid
                ref={grid_product}
                keyExpr="id"
                dataSource={uf_sanphams}
                columnHidingEnabled={false}
                columnAutoWidth={true}
                showBorders={true}
                showColumnLines={false}
                showRowLines={false}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                columnResizingMode={"nextColumn"}
                hoverStateEnabled={true}
                focusedRowEnabled={false}
                height={window.innerHeight - 350}
                noDataText="Không có dữ liệu"
                rowRender={rowRender}
              >
                <Sorting mode="multiple" />
                <Paging defaultPageSize={50} />
                <FilterRow visible={true} />

                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50, 200, 500]}
                  infoText="Trang {0} /{1} ({2} dòng)"
                  showInfo={true}
                />
                {/* <Column
                  dataField="ma_sp"
                  allowEditing={false}
                  caption="Hình ảnh"
                  width={80}
                /> */}

                <Column
                  caption="Tên sản phẩm"
                  allowFiltering="true"
                  dataField="ten_sp"
                  allowEditing={false}
                  width={480}
                />
                {/* <Column allowEditing={false} caption="Giá" width={100} /> */}
              </DataGrid>
            </div>
            <div className="list-right">
              <table className="width-100-percent min-height-70">
                <tbody>
                  <tr>
                    <td className="text-align-left">
                      <table className="width-100-percent">
                        <tbody>
                          <tr>
                            {/* <td><p className="margin-bottom-0">
                          *Mã đơn hàng:{" "}
                          <b className="ma_don_hang">{uf_donhang.ma_don_hang}</b>

                        </p></td> */}
                            <td className="text-align-right">
                              <i>
                                Đã mua:{" "}
                                <b className="ma_don_hang">
                                  {" "}
                                  {uf_donhangchitiets.length > 0
                                    ? uf_donhangchitiets
                                      .map((x) => x.so_luong)
                                      .reduce((a, b) => a + b)
                                    : 0}
                                </b>{" "}
                                sản phẩm, Tổng tiền{" "}
                                <b className="ma_don_hang">
                                  {uf_donhang.id > 0
                                    ? uf_donhang.thanh_tien.toLocaleString()
                                    : ""}
                                </b>
                              </i>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-right">
                              {!uf_donhang.ngay_thu_tien && (
                                <code className="code code-warning">
                                  Chưa thu tiền
                                </code>
                              )}
                              {uf_donhang.ngay_thu_tien && (
                                <code className="code code-success margin-left-5">
                                  Đã thu tiền : {uf_donhang.thuc_nop.toLocaleString()}
                                </code>
                              )}
                              {uf_donhang.ngay_thu_tien &&
                                <React.Fragment>
                                  {uf_donhang_hinhthucthanhtoans.map(x => {
                                    return <React.Fragment>
                                      <code className="code code-info margin-left-5">
                                        {x.short_text}: {x.so_tien.toLocaleString()}
                                      </code>
                                    </React.Fragment>
                                  })}
                                </React.Fragment>
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>

                  </tr>
                </tbody>
              </table>
              <DataGrid
                ref={grid_order}
                keyExpr="id"
                dataSource={uf_donhangchitiets}
                columnHidingEnabled={false}
                columnAutoWidth={true}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                columnResizingMode={"nextColumn"}
                hoverStateEnabled={false}
                focusedRowEnabled={false}
                height={window.innerHeight - 350}
                className="table-noborder-left"
                noDataText="Không có dữ liệu"
                wordWrapEnabled={true}
              >
                <Sorting mode="multiple" />
                <Editing mode="cell" allowUpdating={true}></Editing>
                <Paging defaultPageSize={50} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50, 200, 500]}
                  infoText="Trang {0} /{1} ({2} dòng)"
                  showInfo={true}
                />
                <Column
                  caption="Bỏ SP"
                  width={70}
                  alignment="center"
                  allowEditing={false}
                  cellRender={(data) => {
                    if (!uf_donhang.ngay_thu_tien) {
                      return <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          btnDelete_click(data.data);
                        }}
                      >
                        <i aria-hidden="true" className="icon trash"></i>{" "}
                      </span>
                    }
                  }}
                />
                <Column
                  dataField="ten_sp"
                  caption="Tên SP"
                  allowEditing={false}
                  cellRender={(data) => {
                    return <p className="product-name">{data.data.ten_sp}</p>;
                  }}
                />
                <Column
                  dataField="size"
                  allowEditing={false}
                  caption="Size"
                  width={90}
                />
                <Column
                  dataField="so_luong"
                  caption="Số lượng"
                  format=",##0"
                  width={80}
                  editCellRender={editCellRender}
                />
                <Column
                  dataField="don_gia"
                  caption="Đơn giá"
                  format=",##0"
                  width={80}
                  allowEditing={false}
                />
              </DataGrid>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <DefaultButton onClick={onCancel} text="Đóng" />
          </div>
        </Modal.Actions>
      </Modal>
      {
        is_show_confirm_noptien &&
        <NopTienModal is_show_modal={true}
          uf_donhang_id={uf_donhang.id}
          onCancel={() => {
            dispatch({ type: action_type.CLOSE_CONFIRM_NOPTIEN });
          }}
          onSuccess={(uf_donhang) => {
            dispatch({ type: action_type.SET_DONHANGS, payload: uf_donhang });
            dispatch({ type: action_type.CLOSE_CONFIRM_NOPTIEN });
          }}
        />
      }
      {
        is_show_modal_print &&
        <Modal open={is_show_modal_print} size="large" dimmer={"blurring"} onClose={() => { set_is_show_modal_print(false) }}
          closeOnEscape={false}
          height={window.innerHeight - 250}
          closeOnDimmerClick={false}>
          <Modal.Header>

          </Modal.Header>
          <Modal.Content>
            <div id="embed_print_bienlai">
              <embed id="embed_print_bienlai" src={print_url} width='100%' height={window.innerHeight - 150} type="application/pdf"></embed>
            </div>

          </Modal.Content>
          <Modal.Actions>

            <div>
              <button onClick={() => { set_is_show_modal_print(false) }} className="p-btn p-btn-sm">
                Đóng
              </button>


            </div>
          </Modal.Actions>
        </Modal >
      }
    </React.Fragment >
  );
};
