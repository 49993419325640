import appInfo from '../app-info';
import { call_post_api } from './api_helper';
export async function search(key) {
    return call_post_api(appInfo.api_url + '/ts_hocsinh/search', key);
};
export async function select(data) {
    return call_post_api(appInfo.api_url + '/ts_hocsinh/select_by', data);
};
export async function select_by_id(id) {
    return call_post_api(appInfo.api_url + '/ts_hocsinh/select_by_id', id);
};
export async function select_suggest_email(id) {
    return call_post_api(appInfo.api_url + '/ts_hocsinh/select_suggest_email', id);
};
