import { withNavigationWatcher } from "./contexts/navigation";
import {
  HomePage,
  sis_nhomthanhphandiem_page,
  uf_kho_page,
  uf_sanpham_page,
  uf_dongia_page,
  uf_donhang_page,
  uf_donhang_summary_page,
  uf_donhang_sales_page,
  uf_nhapkho_page,
  uf_category_page
} from "./pages";

const routes = [
  {
    path: "/home",
    component: HomePage,
  },
  {
    path: "/sis_nhomthanhphandiem",
    component: sis_nhomthanhphandiem_page,
  },
  {
    path: "/uf_kho",
    component: uf_kho_page,
  },
  {
    path: "/uf_sanpham",
    component: uf_sanpham_page,
  },
  {
    path: "/uf_dongia",
    component: uf_dongia_page,
  },
  {
    path: "/uf_donhang_summary",
    component: uf_donhang_summary_page,
  },
  {
    path: "/uf_donhang_sales",
    component: uf_donhang_sales_page,
  },
  {
    path: "/uf_donhang",
    component: uf_donhang_page,
  },
  {
    path: "/uf_nhapkho",
    component: uf_nhapkho_page,
  },
  {
    path: "/uf_category",
    component: uf_category_page,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
