import React, {
  useEffect,
  useRef,
  useReducer,
  useCallback,
  useContext,
} from "react";
import { Transition, Confirm } from "semantic-ui-react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import {
  PrimaryButton,
  DefaultButton,
  DangerButton,
} from "../../components/editor/button";
import { status_enum, action_type, ini_state, reducer } from "./reducer";
import { StoreContext } from "../../contexts/store";
import DataGrid, {
  Sorting,
  Pager,
  Paging,
  Selection,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Column,
} from "devextreme-react/data-grid";

import { select_all, remove } from "../../api/uf_dongia";

import DetailModal from "./detail_modal/detail_modal";

export default () => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const grid = useRef(null);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const {
    status,
    dongias,
    uf_dongia_selected,
    is_show_confirm,
    is_show_confirm_multi,
    is_show_detail_modal,
    selectedRowKeys,
  } = state;
  useEffect(() => {
    if (status === status_enum.is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [status]);
  useEffect(() => {
    reload();
  }, []);

  const reload = useCallback(async () => {
    dispatch({ type: action_type.BEGIN_SELECT_DONGIA });
    const res = await select_all();
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_DONGIA,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.END_SELECT_DONGIA, payload: [] });
    }
  }, []);
  const onSelectionChanged = ({ selectedRowKeys }) => {
    dispatch({
      type: action_type.MULTI_SELECT_CHANGE,
      payload: selectedRowKeys,
    });
  };
  const btnCancel_click = useCallback(() => {
    dispatch({ type: action_type.CLOSE_DETAIL_MODAL });
  }, []);
  const btnAdd_click = useCallback(() => {
    dispatch({ type: action_type.SHOW_DETAIL_MODAL, payload: { id: 0 } });
  }, []);
  const btnEdit_click = (data) => {
    dispatch({ type: action_type.SHOW_DETAIL_MODAL, payload: data });
  };
  const btnDelete_click = (data) => {
    dispatch({ type: action_type.SHOW_CONFIRM, payload: data });
  };
  const btnDeleteMulti_click = () => {
    dispatch({
      type: action_type.SHOW_CONFIRM_MULTI,
      payload: selectedRowKeys,
    });
  };
  const btnCancel_delete_click = useCallback(() => {
    dispatch({ type: action_type.CLOSE_CONFIRM });
  }, []);
  const btnCancel_delete_multi_click = useCallback(() => {
    dispatch({ type: action_type.CLOSE_CONFIRM_MULTI });
  }, []);
  const onSuccess = () => {
    dispatch({ type: action_type.CLOSE_DETAIL_MODAL });
    reload();
  };
  const onDelete = async () => {
    dispatch({ type: action_type.BEGIN_SAVING });
    const res = await remove([uf_dongia_selected.id]);
    if (res.is_success) {
      set_notification({ type: "success", text: "Cập nhật thành công" });
      reload();
      dispatch({ type: action_type.CLOSE_CONFIRM });
    } else {
      dispatch({ type: action_type.END_SAVING });
      set_notification({ type: "error", text: res.message });
    }
  };
  const onDeleteMulti = async () => {
    dispatch({ type: action_type.BEGIN_SAVING });
    const res = await remove(selectedRowKeys);
    if (res.is_success) {
      set_notification({ type: "success", text: "Cập nhật thành công" });
      reload();
      dispatch({ type: action_type.CLOSE_CONFIRM_MULTI });
    } else {
      dispatch({ type: action_type.END_SAVING });
      set_notification({ type: "error", text: res.message });
    }
  };

  const canAccessUpdate = (data) => {
    return (
      can_accessed("api/uf_dongia/update") && (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            btnEdit_click(data.data);
          }}
        >
          <i aria-hidden="true" className="icon edit"></i>{" "}
        </span>
      )
    );
  };
  const canAccessDelete = (data) => {
    return (
      can_accessed("api/uf_dongia/delete") && (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            btnDelete_click(data.data);
          }}
        >
          <i aria-hidden="true" className="icon trash"></i>{" "}
        </span>
      )
    );
  };
  const commandCellRender = (data) => {
    return <div>{canAccessUpdate(data)}</div>;
  };

  return (
    <React.Fragment>
      <Transition
        animation="fade right"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <div className="row">
            <div className="col-md-12 padding-top-1rem padding-left-2rem">
              <Toolbar className="bg-background">
                <Item
                  location="before"
                  locateInMenu="never"
                  render={() => {
                    return <h3>Danh mục đơn giá</h3>;
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      can_accessed("api/uf_dongia/insert") && (
                        <PrimaryButton
                          text={"Thêm"}
                          icon="fas fa-plus"
                          onClick={btnAdd_click}
                        />
                      )
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      can_accessed("api/uf_dongia/delete") && (
                        <DangerButton
                          text={
                            selectedRowKeys.length > 0
                              ? `Xóa (${selectedRowKeys.length})`
                              : "Xóa"
                          }
                          icon="fas fa-trash"
                          onClick={btnDeleteMulti_click}
                          disabled={!selectedRowKeys.length}
                        />
                      )
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <DefaultButton
                        text={"Export"}
                        icon="fas fa-file-export"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      />
                    );
                  }}
                />
              </Toolbar>
            </div>
          </div>
          <DataGrid
            ref={grid}
            keyExpr="id"
            dataSource={dongias}
            columnHidingEnabled={false}
            columnAutoWidth={true}
            showBorders={true}
            showColumnLines={false}
            showRowLines={false}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            hoverStateEnabled={true}
            focusedRowEnabled={false}
            selectedRowKeys={selectedRowKeys}
            onSelectionChanged={onSelectionChanged}
            height={window.innerHeight - 150}
            className="table-noborder-left"
            noDataText="Không có dữ liệu"
          >
            <Selection mode="multiple" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={50} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <HeaderFilter visible={true} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50, 200, 500]}
              infoText="Trang {0} /{1} ({2} dòng)"
              showInfo={true}
            />
            <Column caption="Thao tác" cellRender={commandCellRender} />
            <Column dataField="ma_kho" caption="Mã kho" />
            <Column dataField="ten_kho" caption="Tên kho" />
            <Column dataField="ten_sp" caption="Tên sản phẩm" />
            <Column dataField="ma_sp_chitiet" caption="Mã sản phẩm" />
            <Column dataField="size" caption="Size" />
            <Column dataField="don_gia" caption="Đơn giá" format=",##0" />
            <Column
              dataField="don_gia_khuyen_mai"
              caption="Đơn giá khuyến mại"
              format=",##0"
            />
            <Column dataField="" caption="Tồn kho" alignment={"center"} >
              <Column
                dataField="so_luong_nhap"
                caption="Đã nhập"
                format=",##0"
              />
              <Column
                dataField="so_luong_xuat"
                caption="Đã bán"
                format=",##0"
              />
              <Column
                dataField="so_luong_ton"
                caption="Còn lại"
                format=",##0"
              />
            </Column>

          </DataGrid>
        </div>
      </Transition>

      {is_show_detail_modal && (
        <DetailModal
          onCancel={btnCancel_click}
          onSuccess={onSuccess}
          uf_dongia_id={uf_dongia_selected ? uf_dongia_selected.id : 0}
        />
      )}
      {is_show_confirm && (
        <Confirm
          open={true}
          dimmer={"blurring"}
          header="Lưu ý"
          content="Bạn có chắc chắn muốn xóa đơn giá này ?"
          size="mini"
          cancelButton={() => {
            return (
              <DefaultButton text="Đóng" onClick={btnCancel_delete_click} />
            );
          }}
          confirmButton={() => {
            return (
              <DangerButton
                icon="fas fa-trash"
                text="Xóa đơn giá"
                isLoading={status === status_enum.is_saving}
                onClick={onDelete}
              />
            );
          }}
        />
      )}
      {is_show_confirm_multi && (
        <Confirm
          open={true}
          dimmer={"blurring"}
          header="Lưu ý"
          content={`Bạn có chắc chắn muốn xóa ${selectedRowKeys.length > 1 ? selectedRowKeys.length : ""
            } đơn giá này ?`}
          size="mini"
          cancelButton={() => {
            return (
              <DefaultButton
                text="Đóng"
                onClick={btnCancel_delete_multi_click}
              />
            );
          }}
          confirmButton={() => {
            return (
              <DangerButton
                icon="fas fa-trash"
                text={`Xóa ${selectedRowKeys.length} đơn giá`}
                isLoading={status === status_enum.is_saving}
                onClick={onDeleteMulti}
              />
            );
          }}
        />
      )}
    </React.Fragment>
  );
};
