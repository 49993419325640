import moment from "moment";

export const status_enum = {
  is_loading: "is_loading",
  is_completed: "is_completed",
  is_saving: "is_saving",
};
export const ini_state = {
  status: status_enum,
  selectedRowKeys: [],
  orders: [],
  sf_hinhthucthanhtoans: [],
  searchModel: {
    tu_ngay: new Date(),
    den_ngay: new Date(),
    tu_ngay_text: moment(new Date()).format("DD/MM/YYYY"),
    den_ngay_text: moment(new Date()).format("DD/MM/YYYY")
  },
  uf_donhang_hinhthucthanhtoans: [],
  is_show_confirm_cap_nhat_giao_hang: false,
  is_show_confirm_huy_phieu_treo: false,
  is_show_confirm_huy_donhang: false,
};
export const action_type = {
  BEGIN_SEARCH_ORDERS: "BEGIN_SEARCH_ORDERS",
  END_SEARCH_ORDERS: "END_SEARCH_ORDERS",

  SET_TRANGTHAIDONHANG: "SET_TRANGTHAIDONHANG",
  SET_KHO: "SET_KHO",
  SET_TU_NGAY: "SET_TU_NGAY",
  SET_DEN_NGAY: "SET_DEN_NGAY",
  SET_HOCSINH: "SET_HOCSINH",

  SET_SEARCH_MODEL: "SET_SEARCH_MODEL",

  SET_HINHTHUCTHANHTOANS: "SET_HINHTHUCTHANHTOANS",

  MULTI_SELECT_CHANGE: "MULTI_SELECT_CHANGE",

  SHOW_CONFIRM_GIAO_HANG: "SHOW_CONFIRM_GIAO_HANG",
  CLOSE_CONFIRM_GIAO_HANG: "CLOSE_CONFIRM_GIAO_HANG",

  SHOW_CONFIRM_HUY_DONHANG: "SHOW_CONFIRM_HUY_DONHANG",
  CLOSE_CONFIRM_HUY_DONHANG: "CLOSE_CONFIRM_HUY_DONHANG",

  SHOW_CONFIRM_HUY_PHIEU_TREO: "SHOW_CONFIRM_HUY_PHIEU_TREO",
  CLOSE_CONFIRM_HUY_PHIEU_TREO: "CLOSE_CONFIRM_HUY_PHIEU_TREO",

  BEGIN_UPDATE_ORDERS: "BEGIN_UPDATE_ORDERS",
  END_UPDATE_ORDERS: "END_UPDATE_ORDERS",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.SHOW_CONFIRM_GIAO_HANG:
      return {
        ...state,
        is_show_confirm_cap_nhat_giao_hang: true,
      };

    case action_type.CLOSE_CONFIRM_GIAO_HANG:
      return {
        ...state,
        is_show_confirm_cap_nhat_giao_hang: false,
      };
    case action_type.SHOW_CONFIRM_HUY_PHIEU_TREO:
      return {
        ...state,
        is_show_confirm_huy_phieu_treo: true,
      };

    case action_type.CLOSE_CONFIRM_HUY_PHIEU_TREO:
      return {
        ...state,
        is_show_confirm_huy_phieu_treo: false,
      };
    case action_type.SHOW_CONFIRM_HUY_DONHANG:
      return {
        ...state,
        is_show_confirm_huy_donhang: true,
      };

    case action_type.CLOSE_CONFIRM_HUY_DONHANG:
      return {
        ...state,
        is_show_confirm_huy_donhang: false,
      };
    case action_type.BEGIN_SEARCH_ORDERS:
      return {
        ...state,
        status: status_enum.is_loading,
      };
    case action_type.END_SEARCH_ORDERS:
      return {
        ...state,
        status: status_enum.is_completed,
        orders: action.payload.uf_donhangs,
        uf_donhang_hinhthucthanhtoans: action.payload.uf_donhang_hinhthucthanhtoans
      };
    case action_type.BEGIN_UPDATE_ORDERS:
      return {
        ...state,
        status: status_enum.is_saving,
      };
    case action_type.END_UPDATE_ORDERS:
      return {
        ...state,
        status: status_enum.is_completed,
      };

    case action_type.SET_TRANGTHAIDONHANG:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          uf_trangthaidonhang_ids: action.payload,
        },
      };
    case action_type.SET_HOCSINH:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          hocsinh: action.payload,
        },
      };
    case action_type.SET_TU_NGAY:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          tu_ngay: action.payload,
        },
      };
    case action_type.SET_DEN_NGAY:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          den_ngay: action.payload,
        },
      };
    case action_type.SET_KHO:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          uf_kho_id: action.payload,
        },
      };
    case action_type.MULTI_SELECT_CHANGE:
      return {
        ...state,
        selectedRowKeys: action.payload,
      };
    case action_type.SET_HINHTHUCTHANHTOANS:
      return {
        ...state,
        sf_hinhthucthanhtoans: action.payload,
      };
    case action_type.SET_SEARCH_MODEL:
      return {
        ...state,
        searchModel: action.payload,
      };
    default:
      return state;
  }
};
