import React from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/user-panel";
import "./header.scss";
import { Template } from "devextreme-react/core/template";
import { useHistory } from "react-router-dom";
import { Popup, Image } from "semantic-ui-react";
import { useAuth } from "../../contexts/auth";
import appInfo from "../../app-info";
const renderBrandName = () => {
  return (
    <a>
      <div className="toolbar-label header-title">
        QUẢN LÝ BÁN ĐỒNG PHỤC, HỌC PHẨM
      </div>
    </a>
  );
};
export default ({ menuToggleEnabled, title, toggleMenu, menuStatus }) => {
  const { user, signOut } = useAuth();

  let history = useHistory();
  const renderLogo = () => {
    return (
      <a
        onClick={() => {
          // history.push("/home");
          window.location.href = appInfo.home_url;
        }}
      >
        <img
          src="3s_logo.png"
          className="header-logo"
          style={{ height: 40 }}
        ></img>
      </a>
    );
  };
  const PopupContent = (apps) => {
    return (
      <div className="row width-400">
        {apps.map((x) => {
          return (
            <div className="col-md-4" key={x.id}>
              <div
                className={
                  "system-other padding-1rem" +
                  (!x.is_accessed ? " system-other-disabled" : "")
                }
              >
                <a
                  onClick={() => {
                    if (x.is_accessed) {
                      if (x.url) {
                        if (x.url.split("/")[0].includes("http")) {
                          window.location.href = x.url;
                        }
                      }
                    }
                  }}
                >
                  <Image
                    src={"/images/" + x.icon}
                    className="system-other-img"
                  />
                  <p className="system-other-name">{x.name}</p>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <header className={"header-component"}>
      <Toolbar className={"header-toolbar"}>
        <Item location={"before"} render={renderLogo}></Item>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Button
            className="expand-menu"
            stylingMode="text"
            onClick={toggleMenu}
          >
            <i
              className={
                menuStatus === 1
                  ? "fas fa-chevron-right"
                  : "fas fa-chevron-left"
              }
            ></i>
          </Button>
        </Item>
        <Item
          location={"center"}
          cssClass={"header-title"}
          text={title}
          visible={!!title}
          render={renderBrandName}
        />
        <Item
          visible={true}
          location={"after"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Popup
            content={() => {
              return PopupContent(user.sub_systems);
            }}
            on="click"
            popper={{ id: "popper-container", style: { zIndex: 2000 } }}
            trigger={<Button icon="menu" stylingMode="text" />}
          />
        </Item>
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            width={210}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} />
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel menuMode={"list"} />
        </Template>
      </Toolbar>
    </header>
  );
};
