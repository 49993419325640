import appInfo from '../app-info';
import { call_post_api } from './api_helper';

export const select_all = async () => {
  return call_post_api(appInfo.api_url + '/uf_category/select_all');
};
export const insert = async (data) => {
  return call_post_api(appInfo.api_url + '/uf_category/insert', data);
};
export const update = async (data) => {
  return call_post_api(appInfo.api_url + '/uf_category/update', data);
};
export const remove = async (ids) => {
  return call_post_api(appInfo.api_url + '/uf_category/delete', ids);
};

export const select_by_id = async (id) => {
  return call_post_api(appInfo.api_url + '/uf_category/select_by_id', id);
};
