import React, { useEffect, useReducer, useContext } from "react";
import { status_enum, action_type, ini_state, reducer } from "./reducer";
import { Modal } from "semantic-ui-react";
import { TextBox } from "devextreme-react/text-box";
import {
  PrimaryButton,
  DefaultButton,
} from "../../../components/editor/button";
import { InValidMessage } from "../../../components/editor/invalid_message";

import { StoreContext } from "../../../contexts/store";
import { useForm } from "react-hook-form";

import { select_by_id, insert, update } from "../../../api/uf_sanpham";
import { SelectBox } from "devextreme-react";
import { select_all } from "../../../api/uf_category";

export default ({ uf_sanpham_id, onCancel, onSuccess }) => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const { register, trigger, errors } = useForm();
  const { status, uf_sanpham, uf_categories } = state;
  useEffect(() => {
    if (uf_sanpham_id > 0) {
      request_select_by_id();
    } else {
      dispatch({ type: action_type.CHANGE_SANPHAM, payload: {} });
    }
    get_all_categories();
  }, [uf_sanpham_id]);
  const request_select_by_id = async () => {
    dispatch({ type: action_type.BEGIN_SELECT_SANPHAM });
    const res = await select_by_id(uf_sanpham_id);
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_SANPHAM,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.END_SELECT_SANPHAM, payload: {} });
      set_notification({ type: "error", text: res.message });
    }
  };

  const get_all_categories = async () => {
    dispatch({ type: action_type.BEGIN_SELECT_CATEGORIES });
    const res = await select_all();
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_CATEGORIES,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.END_SELECT_CATEGORIES, payload: [] });
      set_notification({ type: "error", text: res.message });
    }
  };

  const handleSaveChanges = async () => {
    trigger().then(async (isValid) => {
      if (isValid) {
        dispatch({ type: action_type.BEGIN_SAVING });
        let res;
        if (uf_sanpham_id > 0) {
          res = await update(uf_sanpham);
        } else {
          res = await insert(uf_sanpham);
        }
        dispatch({ type: action_type.END_SAVING });
        if (res.is_success) {
          set_notification({ type: "success", text: "Cập nhật thành công" });
          onSuccess();
        } else {
          set_notification({ text: res.message, type: "error" });
        }
      }
    });
  };
  return (
    <React.Fragment>
      <Modal
        open={true}
        size="mini"
        dimmer={"blurring"}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {uf_sanpham_id > 0 ? <p>Cập nhật</p> : <p>Thêm mới</p>}
        </Modal.Header>
        <Modal.Content>
          <form className="ui">
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Mã sản phẩm</div>
                <br></br>
                {uf_sanpham_id > 0 ? (
                  <TextBox
                    value={uf_sanpham.ma_sp}
                    stylingMode="outlined"
                    disabled={true}
                  />
                ) : (
                  <TextBox
                    value={uf_sanpham.ma_sp}
                    stylingMode="outlined"
                    onValueChanged={(e) => {
                      dispatch({
                        type: action_type.CHANGE_SANPHAM,
                        payload: {
                          ...uf_sanpham,
                          ma_sp: e.value,
                        },
                      });
                      trigger("ma_sanpham");
                    }}
                  />
                )}
                <InValidMessage
                  name="ma_sanpham"
                  defaultValue={uf_sanpham.ma_sp}
                  register={register({ required: true })}
                  errors={errors}
                  message="Vui lòng điền mã sản phẩm"
                />
              </div>
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Tên sản phẩm</div>
                <br></br>
                <TextBox
                  value={uf_sanpham.ten_sp}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_SANPHAM,
                      payload: {
                        ...uf_sanpham,
                        ten_sp: e.value,
                      },
                    });
                    trigger("ten_sanpham");
                  }}
                />
                <InValidMessage
                  name="ten_sanpham"
                  defaultValue={uf_sanpham.ten_sp}
                  register={register({ required: true })}
                  errors={errors}
                  message="Vui lòng điền tên sản phẩm"
                />
              </div>
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Nhóm sản phẩm</div>
                <br></br>
                <SelectBox
                  dataSource={uf_categories}
                  displayExpr={"category"}
                  valueExpr={"id"}
                  placeholder="Chọn nhóm sản phẩm"
                  searchEnabled="true"
                  value={uf_sanpham.uf_category_id}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_SANPHAM,
                      payload: {
                        ...uf_sanpham,
                        uf_category_id: e.value,
                      },
                    });
                    trigger("loai_sanpham");
                  }}
                />
                <InValidMessage
                  name="loai_sanpham"
                  defaultValue={uf_sanpham.uf_category_id}
                  register={register({ required: true })}
                  errors={errors}
                  message="Vui lòng chọn nhóm sản phẩm"
                />
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <DefaultButton onClick={onCancel} text="Đóng" />
            <PrimaryButton
              onClick={handleSaveChanges}
              text={uf_sanpham_id > 0 ? "Cập nhật" : "Thêm mới"}
              disabled={status === status_enum.is_saving}
              isLoading={status === status_enum.is_saving}
              icon="fas fa-check"
            />
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};
