import React, { useEffect, useRef, useReducer } from "react";
import { Transition } from "semantic-ui-react";
import Toolbar, { Item } from "devextreme-react/toolbar";

import DataGrid, {
  Sorting,
  Pager,
  Paging,
  Selection,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Column,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { DateBox, TextBox } from "devextreme-react";

import { sales } from "../../api/uf_donhang";
import ComboboxKho from "../../components/editor/cmb_kho";
import { DefaultButton, PrimaryButton, DangerButton } from "../../components/editor/button";
import { status_enum, action_type, ini_state, reducer } from "./reducer";
import CbxTrangThaiDonHang from "../../components/editor/cmb_trangthaidonhang";
import moment from "moment";

export default () => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const grid = useRef(null);

  const { status, orders, searchModel } = state;
  useEffect(() => {
    if (status === status_enum.is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [status]);
  useEffect(() => {
    searchSales();
  }, []);
  useEffect(() => {
    let data = { ...searchModel }
    if (data.tu_ngay) {
      data.tu_ngay_text = moment(data.tu_ngay).format("DD/MM/YYYY")
    } else {
      data.tu_ngay_text = null;
    }
    if (data.den_ngay) {
      data.den_ngay_text = moment(data.den_ngay).format("DD/MM/YYYY")
    } else {
      data.den_ngay_text = null;
    }
    dispatch({ type: action_type.SET_SEARCH_MODEL, payload: data })
  }, [searchModel.tu_ngay, searchModel.den_ngay])
  const searchSales = async () => {
    dispatch({
      type: action_type.BEGIN_SEARCH_ORDERS,
    });
    const res = await sales(searchModel);
    if (res.is_success) {
      dispatch({
        type: action_type.END_SEARCH_ORDERS,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.END_SEARCH_ORDERS, payload: [] });
    }
  };


  return (
    <React.Fragment>
      <Transition
        animation="fade right"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <div className="row">
            <div className="col-md-12 padding-top-1rem padding-left-2rem">
              <Toolbar className="bg-background">
                <Item
                  location="before"
                  locateInMenu="auto"
                  render={() => {
                    return <h3>Đơn hàng chi tiết</h3>;
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <ComboboxKho
                        value={searchModel.uf_kho_id}
                        onValueChanged={(value) => {
                          dispatch({
                            type: action_type.SET_KHO,
                            payload: value,
                          });
                        }}
                      />
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <CbxTrangThaiDonHang
                        value={searchModel.uf_trangthaidonhang_ids}
                        onValueChanged={(value) => {
                          dispatch({
                            type: action_type.SET_TRANGTHAIDONHANG,
                            payload: value,
                          });
                        }}
                      />
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <DateBox
                        displayFormat="dd/MM/yyyy"
                        showClearButton={false}
                        onValueChanged={(e) => {
                          dispatch({
                            type: action_type.SET_TU_NGAY,
                            payload: e.value,
                          });
                        }}
                        value={searchModel.tu_ngay}
                        placeholder="Từ ngày"
                        max={searchModel.den_ngay}
                        type="date"
                        stylingMode="outlined"
                        displayFormat={"'Từ': dd/MM/yy"}
                      />
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <DateBox
                        placeholder="Đến ngày"
                        displayFormat="dd/MM/yyyy"
                        showClearButton={false}
                        onValueChanged={(e) => {
                          dispatch({
                            type: action_type.SET_DEN_NGAY,
                            payload: e.value,
                          });
                        }}
                        value={searchModel.den_ngay}
                        min={searchModel.tu_ngay}
                        type="date"
                        stylingMode="outlined"
                        displayFormat={"'Đến': dd/MM/yy"}
                      />
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <TextBox
                        stylingMode="outlined"
                        placeholder="Tên hoặc Mã HS"
                        onValueChanged={(e) => {
                          dispatch({
                            type: action_type.SET_HOCSINH,
                            payload: e.value,
                          });
                        }}
                      />
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <DefaultButton
                        icon="fas fa-search"
                        type="submit"
                        text="Tìm kiếm"
                        onClick={searchSales}
                      />
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <DefaultButton
                        text={"Export"}
                        icon="fas fa-file-export"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      />
                    );
                  }}
                />
              </Toolbar>
            </div>


          </div>
          <DataGrid
            ref={grid}
            keyExpr="id"
            dataSource={orders}
            columnHidingEnabled={false}
            columnAutoWidth={true}
            showBorders={true}
            showColumnLines={false}
            showRowLines={true}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            hoverStateEnabled={true}
            focusedRowEnabled={false}
            height={window.innerHeight - 150}
            className="table-noborder-left"
            noDataText="Không có dữ liệu"
          >
            <Selection mode="single" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={50} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <HeaderFilter visible={true} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50, 200, 500]}
              infoText="Trang {0} /{1} ({2} dòng)"
              showInfo={true}
            />

            <Column caption="Đơn hàng" alignment="center" fixed>
              <Column
                dataField="trangthaidonhang"
                caption="TT đơn hàng"
                allowFiltering={false}
              />
              <Column
                dataField="ma_don_hang"
                caption="Mã đơn hàng"
              />
              <Column dataField="ma_don_hang_goc" caption="Mã đơn hàng gốc" />
              <Column
                dataField="created_time"
                allowFiltering={false}
                caption="Ngày tạo"
                dataType="date"
                format="dd/MM/yyyy HH:mm"
              />
            </Column>
            <Column caption="Sản phẩm" alignment="center">
              <Column dataField="ma_sp_chitiet" caption="Mã sản phẩm" />
              <Column dataField="ten_sp" caption="Tên sản phẩm" />
              <Column dataField="size" caption="Size" />
              <Column dataField="so_luong" caption="Số lượng" format=",##0" />
              <Column
                dataField="so_luong_doi_tra"
                caption="Đã đổi trả"
              />
              <Column dataField="don_gia" caption="Đơn giá" format=",##0" cellRender={(cell) => {
                return <p className="font-mono">{cell.data.don_gia.toLocaleString()}</p>
              }} />
              <Column dataField="tong_tien" caption="Thành tiền" format=",##0" minWidth={150} cellRender={(cell) => {
                return <p className="font-mono">{cell.data.tong_tien.toLocaleString()}</p>
              }} />
            </Column>
            <Column caption="Thông tin đơn hàng" alignment="center">
              <Column
                dataField="ngay_thu_tien"
                caption="Ngày thu tiền"
                allowFiltering={false}
                dataType="date"
                format="dd/MM/yyyy HH:mm"
              />
              <Column dataField="nguoi_thu_tien" caption="Người thu tiền" />


              <Column
                dataField="nguoi_tao"
                caption="Người tạo"
                allowFiltering={false}
              />
              <Column
                dataField="ngay_hoan_thanh"
                caption="Ngày hoành thành"
                allowFiltering={false}
                dataType="date"
                format="dd/MM/yyyy HH:mm"
              />
            </Column>
            <Column caption="Thông tin học sinh" alignment="center">
              <Column dataField="ma_hs" caption="Mã HS" />
              <Column dataField="ho_ten" caption="Tên HS" />
              <Column dataField="ten_truong" caption="Cơ sở" />
              <Column dataField="ten_he" caption="Cấp học" />
              <Column dataField="ten_khoi" caption="Khối" />
              <Column dataField="lop_dang_hoc" caption="Lớp" />
              <Column dataField="dia_chi_tt" caption="Địa chỉ" />
              <Column dataField="dien_thoai_lien_he" caption="Điện thoại LH" />
              <Column dataField="ho_ten_lien_he" caption="Tên LH" />
            </Column>
            {/* 
            <Summary>
              <TotalItem
                column="tong_tien"
                summaryType="sum"
                valueFormat=",##0"
                alignment="left"
                displayFormat="Tổng: {0}"
              />
            </Summary> */}
          </DataGrid>
        </div>
      </Transition>
    </React.Fragment>
  );
};
