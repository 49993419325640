import appInfo from "../app-info";
import { call_post_api } from "./api_helper";

export const select_by_hocsinh = async (ts_hocsinh_id) => {
  return call_post_api(
    appInfo.api_url + "/uf_donhang/select_by_hocsinh",
    ts_hocsinh_id
  );
};
export const select_all = async (hocsinh_id) => {
  return call_post_api(appInfo.api_url + "/uf_donhang/select_all", hocsinh_id);
};
export const summary = async (searchModel) => {
  return call_post_api(appInfo.api_url + "/uf_donhang/summary", searchModel);
};
export const sales = async (searchModel) => {
  return call_post_api(appInfo.api_url + "/uf_donhang/sales", searchModel);
};
export const update_giao_hang = async (selectedRowKeys) => {
  return call_post_api(
    appInfo.api_url + "/uf_donhang/update_giaohang",
    selectedRowKeys
  );
};
export const update_huy_phieutreo = async (selectedRowKeys) => {
  return call_post_api(
    appInfo.api_url + "/uf_donhang/update_huy_phieutreo",
    selectedRowKeys
  );
};
export const update_huy_donhang = async (selectedRowKeys) => {
  return call_post_api(
    appInfo.api_url + "/uf_donhang/update_huy_donhang",
    selectedRowKeys
  );
};
export const update_quantity = async (data) => {
  return call_post_api(appInfo.api_url + "/uf_donhang/update_quantity", data);
};
export const select_by_student_id = async (student_id) => {
  return call_post_api(
    appInfo.api_url + "/uf_donhang/select_by_student_id",
    student_id
  );
};
export const select_by_id = async (id) => {
  return call_post_api(appInfo.api_url + "/uf_donhang/select_by_id", id);
};
export const pay = async (id) => {
  return call_post_api(appInfo.api_url + "/uf_donhang/pay", id);
};
export const print = async (id) => {
  return call_post_api(appInfo.api_url + "/uf_donhang/print", id);
};
export const print_khongthuphi = async (id) => {
  return call_post_api(appInfo.api_url + "/uf_donhang/print_khongthuphi", id);
};
export const print_phieuchi = async (id) => {
  return call_post_api(appInfo.api_url + "/uf_donhang/print_phieuchi", id);
};
