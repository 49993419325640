export const status_enum = {
  is_loading: 'is_loading',
  is_completed: 'is_completed',
  is_saving: 'is_saving',
};
export const ini_state = {
  status: status_enum,
  products: [],
  uf_sanpham_selected: {},
  is_show_confirm: false,
  is_show_detail_modal: false,
};
export const action_type = {
  BEGIN_SELECT_SP: 'BEGIN_SELECT_SP',
  END_SELECT_SP: 'END_SELECT_SP',

  SHOW_CONFIRM: 'SHOW_CONFIRM',
  CLOSE_CONFIRM: 'CLOSE_CONFIRM',

  SHOW_DETAIL_MODAL: 'SHOW_DETAIL_MODAL',
  CLOSE_DETAIL_MODAL: 'CLOSE_DETAIL_MODAL',

  BEGIN_SAVING: 'BEGIN_SAVING',
  END_SAVING: 'END_SAVING',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.BEGIN_SELECT_SP:
      return {
        ...state,
        status: status_enum.is_loading,
      };
    case action_type.END_SELECT_SP:
      return {
        ...state,
        status: status_enum.is_completed,
        products: action.payload,
      };

    case action_type.SHOW_CONFIRM:
      return {
        ...state,
        uf_sanpham_selected: action.payload,
        is_show_confirm: true,
      };

    
    case action_type.CLOSE_CONFIRM:
      return {
        ...state,
        is_show_confirm: false,
      };

    
    case action_type.SHOW_DETAIL_MODAL:
      return {
        ...state,
        uf_sanpham_selected: action.payload,
        is_show_detail_modal: true,
      };

    case action_type.CLOSE_DETAIL_MODAL:
      return {
        ...state,
        is_show_detail_modal: false,
      };
    case action_type.BEGIN_SAVING:
      return {
        ...state,
        status: status_enum.is_saving,
      };

    case action_type.END_SAVING:
      return {
        ...state,
        status: status_enum.is_completed,
      };

    
    default:
      return state;
  }
};
