import appInfo from '../app-info';
import { call_post_api } from './api_helper';
export const select_all = async () => {
    return call_post_api(appInfo.api_url + '/sis_nhomthanhphandiem/select_all');
};
export const insert = async (data) => {
    return call_post_api(appInfo.api_url + '/sis_nhomthanhphandiem/insert', data);
};
export const update = async (data) => {
    return call_post_api(appInfo.api_url + '/sis_nhomthanhphandiem/update', data);
};
export const remove = async (id) => {
    return call_post_api(appInfo.api_url + '/sis_nhomthanhphandiem/delete', id);
};
export const select_by_id = async (id) => {
    return call_post_api(appInfo.api_url + '/sis_nhomthanhphandiem/select_by_id', id);
};
