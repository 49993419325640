export const status_enum = {
  is_loading: "is_loading",
  is_completed: "is_completed",
  is_saving: "is_saving",
};
export const ini_state = {
  status: status_enum,
  orders: [],
  order_selected: {},
  is_show_detail_modal: false,
  is_show_doitra_modal: false,
  is_show_phieuchi_modal: false,
  ts_hocsinh: {
    // dien_thoai: "989340334",
    // email: "thuynhungt34@gmail.com",
    // ho_ten: "Đỗ Đăng Quang",
    // id: 1,
    // ma_hs: "PS216832",
    // ma_tn: "TN000001",
    // ngay_sinh: "2015-12-09T00:00:00",
    // ten_he: "Hệ Chất lượng cao",
    // ten_khoi: "Khối 1",
    // ten_truong: "Tiểu học Phenikaa",
    // title: "[PS216832] - Đỗ Đăng Qua"
  },
  uf_kho: { id: undefined },
};
export const action_type = {
  BEGIN_SELECT_ORDERS: "BEGIN_SELECT_ORDERS",
  END_SELECT_ORDERS: "END_SELECT_ORDERS",

  CHANGE_HOCSINH: "CHANGE_HOCSINH",
  CHANGE_KHO: "CHANGE_KHO",

  SHOW_CONFIRM: "SHOW_CONFIRM",
  CLOSE_CONFIRM: "CLOSE_CONFIRM",

  SHOW_DETAIL_MODAL: "SHOW_DETAIL_MODAL",
  CLOSE_DETAIL_MODAL: "CLOSE_DETAIL_MODAL",

  BEGIN_SAVING: "BEGIN_SAVING",
  END_SAVING: "END_SAVING",

  SHOW_DOITRA_MODAL: "SHOW_DOITRA_MODAL",
  CLOSE_DOITRA_MODAL: "CLOSE_DOITRA_MODAL",

  SHOW_PHIEUCHI_MODAL: "SHOW_PHIEUCHI_MODAL",
  CLOSE_PHIEUCHI_MODAL: "CLOSE_PHIEUCHI_MODAL"
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.CHANGE_HOCSINH:
      return {
        ...state,
        ts_hocsinh: action.payload,
      };
    case action_type.BEGIN_SELECT_ORDERS:
      return {
        ...state,
        status: status_enum.is_loading,
      };
    case action_type.END_SELECT_ORDERS:
      return {
        ...state,
        status: status_enum.is_completed,
        orders: action.payload,
      };

    case action_type.SHOW_DETAIL_MODAL:
      return {
        ...state,
        order_selected: action.payload,
        is_show_detail_modal: true,
      };

    case action_type.CLOSE_DETAIL_MODAL:
      return {
        ...state,
        is_show_detail_modal: false,
      };
    case action_type.BEGIN_SAVING:
      return {
        ...state,
        status: status_enum.is_saving,
      };

    case action_type.END_SAVING:
      return {
        ...state,
        status: status_enum.is_completed,
      };
    case action_type.CHANGE_KHO:
      if (action.payload) {
        localStorage.uf_kho_id = action.payload.id;
      }
      return {
        ...state,
        uf_kho: action.payload,
      };
    case action_type.SHOW_DOITRA_MODAL:
      return {
        ...state,
        is_show_doitra_modal: true,
        order_selected: action.payload,
      };
    case action_type.CLOSE_DOITRA_MODAL:
      return {
        ...state,
        is_show_doitra_modal: false
      };
    case action_type.SHOW_PHIEUCHI_MODAL:
      return {
        ...state,
        is_show_phieuchi_modal: true,
        order_selected: action.payload,
      };
    case action_type.CLOSE_PHIEUCHI_MODAL:
      return {
        ...state,
        is_show_phieuchi_modal: false
      };
    default:
      return state;
  }
};
