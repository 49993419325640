import React, { useReducer, useContext } from "react";
import { status_enum, action_type, ini_state, reducer } from "./reducer";
import { Modal } from "semantic-ui-react";
import { TextArea } from "devextreme-react/text-area";
import {
  PrimaryButton,
  DefaultButton,
} from "../../../components/editor/button";
import { InValidMessage } from "../../../components/editor/invalid_message";

import { StoreContext } from "../../../contexts/store";
import { useForm } from "react-hook-form";

import { update_huy_donhang } from "../../../api/uf_donhang";
export default ({ selectedRowKeys, onCancel, onSuccess }) => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const { register, trigger, errors } = useForm();
  const { status, ly_do_huy } = state;

  const handleSaveChanges = async () => {
    trigger().then(async (isValid) => {
      if (isValid) {
        dispatch({ type: action_type.BEGIN_SAVING });

        const res = await update_huy_donhang({
          ids: selectedRowKeys,
          ly_do_huy,
        });
        dispatch({ type: action_type.END_SAVING });
        if (res.is_success) {
          set_notification({ type: "success", text: "Cập nhật thành công" });
          onSuccess();
        } else {
          set_notification({ text: res.message, type: "error" });
        }
      }
    });
  };
  return (
    <React.Fragment>
      <Modal
        open={true}
        size="mini"
        dimmer={"blurring"}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Hủy đơn hàng</Modal.Header>
        <Modal.Content>
          <form className="ui">
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Lý do hủy đơn hàng</div>
                <br></br>
                <TextArea
                  value={ly_do_huy}
                  stylingMode="outlined"
                  maxLength={250}
                  height={70}
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_LY_DO_HUY,
                      payload: e.value,
                    });
                    trigger("ly_do_huy");
                  }}
                />

                <InValidMessage
                  name="ly_do_huy"
                  defaultValue={ly_do_huy}
                  register={register({ required: true })}
                  errors={errors}
                  message="Vui lòng điền lý do hủy đơn hàng"
                />
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <DefaultButton onClick={onCancel} text="Đóng" />
            <PrimaryButton
              onClick={handleSaveChanges}
              text={"Cập nhật"}
              disabled={status === status_enum.is_saving}
              isLoading={status === status_enum.is_saving}
              icon="fas fa-check"
            />
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};
