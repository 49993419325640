import React, { useEffect, useReducer, useContext } from "react";
import { status_enum, action_type, ini_state, reducer } from "./reducer";
import { Modal } from "semantic-ui-react";
import { TextBox } from "devextreme-react/text-box";
import {
  PrimaryButton,
  DefaultButton,
} from "../../../components/editor/button";
import { InValidMessage } from "../../../components/editor/invalid_message";

import { StoreContext } from "../../../contexts/store";
import { useForm } from "react-hook-form";

import { select_by_id, insert, update } from "../../../api/uf_nhapkho";
import { DateBox, NumberBox, SelectBox } from "devextreme-react";


export default ({ uf_nhapkho_id, uf_sanphamchitiets, uf_khos, onCancel, onSuccess }) => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const { register, trigger, errors } = useForm();
  const { status, uf_nhapkho } = state;
  useEffect(() => {
    if (uf_nhapkho_id > 0) {
      request_select_by_id();
    } else {
      dispatch({ type: action_type.CHANGE_NHAPKHO, payload: {} });
    }

  }, [uf_nhapkho_id]);
  const request_select_by_id = async () => {
    dispatch({ type: action_type.BEGIN_SELECT_NHAPKHO });
    const res = await select_by_id(uf_nhapkho_id);
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_NHAPKHO,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.END_SELECT_NHAPKHO, payload: {} });
      set_notification({ type: "error", text: res.message });
    }
  };



  const handleSaveChanges = async () => {
    trigger().then(async (isValid) => {
      if (isValid) {
        dispatch({ type: action_type.BEGIN_SAVING });
        let res;
        if (uf_nhapkho_id > 0) {
          res = await update(uf_nhapkho);
        } else {
          res = await insert(uf_nhapkho);
        }
        dispatch({ type: action_type.END_SAVING });
        if (res.is_success) {
          set_notification({ type: "success", text: "Cập nhật thành công" });
          onSuccess();
        } else {
          set_notification({ text: res.message, type: "error" });
        }
      }
    });
  };
  return (
    <React.Fragment>
      <Modal
        open={true}
        size="mini"
        dimmer={"blurring"}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {uf_nhapkho_id > 0 ? <p>Cập nhật</p> : <p>Thêm mới</p>}
        </Modal.Header>
        <Modal.Content>
          <form className="ui">
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Sản phẩm</div>
                <br></br>
                <SelectBox
                  dataSource={uf_sanphamchitiets}
                  displayExpr={"ma_sp_chitiet"}
                  valueExpr={"id"}
                  placeholder="Chọn sản phẩm"
                  searchEnabled="true"
                  value={uf_nhapkho.uf_sanphamchitiet_id}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_NHAPKHO,
                      payload: {
                        ...uf_nhapkho,
                        uf_sanphamchitiet_id: e.value,
                      },
                    });
                    trigger("uf_sanphamchitiet_id");
                  }}
                />
                <InValidMessage
                  name="uf_sanphamchitiet_id"
                  defaultValue={uf_nhapkho.uf_sanphamchitiet_id}
                  register={register({ required: true, min: 0 })}
                  errors={errors}
                  message="Vui lòng sản phẩm"
                />
              </div>
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Kho</div>
                <br></br>
                <SelectBox
                  dataSource={uf_khos}
                  displayExpr={"ten_kho"}
                  valueExpr={"id"}
                  placeholder="Chọn kho"
                  searchEnabled="true"
                  value={uf_nhapkho.uf_kho_id}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_NHAPKHO,
                      payload: {
                        ...uf_nhapkho,
                        uf_kho_id: e.value,
                      },
                    });
                    trigger("uf_kho_id");
                  }}
                />
                <InValidMessage
                  name="uf_kho_id"
                  defaultValue={uf_nhapkho.uf_kho_id}
                  register={register({ required: true })}
                  errors={errors}
                  message="Vui lòng kho"
                />
              </div>
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Ngày nhập</div>
                <br></br>
                <DateBox
                  placeholder="Chọn ngày nhập kho"
                  displayFormat="dd/MM/yyyy"
                  showClearButton={false}
                  value={uf_nhapkho.ngay_nhap}
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_NHAPKHO,
                      payload: {
                        ...uf_nhapkho,
                        ngay_nhap: e.value,
                      },
                    });
                    trigger("ngay_nhap");
                  }}
                  type="date"
                  stylingMode="outlined"
                />
                <InValidMessage
                  name="ngay_nhap"
                  defaultValue={uf_nhapkho.ngay_nhap}
                  register={register({ required: true })}
                  errors={errors}
                  message="Vui lòng điền ngày nhập kho"
                />
              </div>
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Số lượng</div>
                <br></br>
                <NumberBox
                  placeholder="Điền số lượng"
                  displayFormat=",##0"
                  showClearButton={false}
                  value={uf_nhapkho.so_luong}
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_NHAPKHO,
                      payload: {
                        ...uf_nhapkho,
                        so_luong: e.value,
                      },
                    });
                    trigger("so_luong");
                  }}
                  type="date"
                  stylingMode="outlined"
                />
                <InValidMessage
                  name="so_luong"
                  defaultValue={uf_nhapkho.so_luong}
                  register={register({ required: true, min: 0 })}
                  errors={errors}
                  message="Vui lòng điền số lượng"
                />
              </div>
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Ghi chú</div>
                <br></br>
                <TextBox
                  displayFormat=",##0"
                  value={uf_nhapkho.ghi_chu}
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_NHAPKHO,
                      payload: {
                        ...uf_nhapkho,
                        ghi_chu: e.value,
                      },
                    });
                  }}
                  stylingMode="outlined"
                />
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <DefaultButton onClick={onCancel} text="Đóng" />
            <PrimaryButton
              onClick={handleSaveChanges}
              text={uf_nhapkho_id > 0 ? "Cập nhật" : "Thêm mới"}
              disabled={status === status_enum.is_saving}
              isLoading={status === status_enum.is_saving}
              icon="fas fa-check"
            />
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};
