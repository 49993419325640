import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { useAuth } from './contexts/auth';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';


export default function () {
    const { user, signOut } = useAuth();
    const default_page = () => {
        return "/home";

    }
    
    return (
        <SideNavBarLayout title={appInfo.title}>
            <Switch>
                {routes.map(({ path, component }) => (
                    <Route
                        exact
                        key={path}
                        path={path}
                        component={component}
                    />
                ))}
                <Redirect to={default_page()} />
            </Switch>
        </SideNavBarLayout>

    );
}
