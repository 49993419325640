import React from 'react'
import { Label } from 'semantic-ui-react';
const InValidMessage = ({ name, defaultValue, register, errors, message }) => {
    //register({ required: true, min: 1 })
    return (
        <React.Fragment>
            <input name={name}
                className="hide"
                defaultValue={defaultValue}
                ref={register}
                ></input>
            {
                errors[name] &&
                <Label basic color='red' pointing>
                    {message}
                </Label>
            }
        </React.Fragment>
    );
}
export { InValidMessage }