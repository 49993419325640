export const status_enum = {
  is_loading: 'is_loading',
  is_completed: 'is_completed',
  is_saving: 'is_saving',
};
export const ini_state = {
  status: status_enum,
  uf_nhapkho: {},
  
};
export const action_type = {
  BEGIN_SELECT_NHAPKHO: 'BEGIN_SELECT_NHAPKHO',
  END_SELECT_NHAPKHO: 'END_SELECT_NHAPKHO',



  CHANGE_NHAPKHO: 'CHANGE_NHAPKHO',

  BEGIN_SAVING: 'BEGIN_SAVING',
  END_SAVING: 'END_SAVING',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.BEGIN_SELECT_NHAPKHO:
      return {
        ...state,
        status: status_enum.is_loading,
      };
    case action_type.END_SELECT_NHAPKHO:
      return {
        ...state,
        status: status_enum.is_completed,
        uf_nhapkho: action.payload,
      };
    
    case action_type.CHANGE_NHAPKHO:
      return {
        ...state,
        uf_nhapkho: action.payload,
      };

    case action_type.BEGIN_SAVING:
      return {
        ...state,
        status: status_enum.is_saving,
      };

    case action_type.END_SAVING:
      return {
        ...state,
        status: status_enum.is_completed,
      };

    default:
      return state;
  }
};
