//PRODUCT
 export default {
     title: 'Quản lý bán lẻ',
     api_url: 'https://phenikaa-retail.3s.edu.vn/api',
     home_url:'https://portal.3s.edu.vn',
     sub_system_id: 3
 };
//UAT
// export default {
//     title: 'Quản lý bán lẻ',
//     api_url: 'http://demo-retail.3s.edu.vn/api',
//     home_url:'http://demo-portal.3s.edu.vn',
//     sub_system_id: 3
// };
//DEV
// export default {
//    title: 'Quản lý bán lẻ',
//    api_url: 'https://localhost:44392/api',
//    home_url: 'https://portal.3s.edu.vn',
//    sub_system_id: 3
// };
