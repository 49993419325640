import React, {
  useEffect,
  useRef,
  useReducer,
  useCallback,
  useContext,
} from "react";
import { Transition, Confirm } from "semantic-ui-react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import {
  PrimaryButton,
  DefaultButton,
  DangerButton,
} from "../../components/editor/button";
import { status_enum, action_type, ini_state, reducer } from "./reducer";
import { StoreContext } from "../../contexts/store";
import DataGrid, {
  Sorting,
  Pager,
  Paging,
  Selection,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Column,
} from "devextreme-react/data-grid";

import { select_all, remove } from "../../api/sis_nhomthanhphandiem";

import DetailModal from "./detail_modal/detail_modal";

export default () => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const grid = useRef(null);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const {
    status,
    sis_nhomthanhphandiems,
    sis_nhomthanhphandiem_selected,
    is_show_confirm,
    is_show_detail_modal,
  } = state;
  useEffect(() => {
    if (status === status_enum.is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [status]);
  useEffect(() => {
    reload();
  }, []);

  const reload = useCallback(async () => {
    dispatch({ type: action_type.BEGIN_SELECT_NHOMTHANHPHANS });
    const res = await select_all();
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_NHOMTHANHPHANS,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.END_SELECT_NHOMTHANHPHANS, payload: [] });
    }
  }, []);
  const btnCancel_click = useCallback(() => {
    dispatch({ type: action_type.CLOSE_DETAIL_MODAL });
  }, []);
  const btnAdd_click = useCallback(() => {
    dispatch({ type: action_type.SHOW_DETAIL_MODAL, payload: { id: 0 } });
  }, []);
  const btnEdit_click = (data) => {
    dispatch({ type: action_type.SHOW_DETAIL_MODAL, payload: data });
  };
  const btnDelete_click = (data) => {
    dispatch({ type: action_type.SHOW_CONFIRM, payload: data });
  };
  const btnCancel_delete_click = useCallback(() => {
    dispatch({ type: action_type.CLOSE_CONFIRM });
  }, []);
  const onSuccess = () => {
    dispatch({ type: action_type.CLOSE_DETAIL_MODAL });
    reload();
  };
  const onDelete = async () => {
    dispatch({ type: action_type.BEGIN_SAVING });
    const res = await remove(sis_nhomthanhphandiem_selected.id);
    if (res.is_success) {
      set_notification({ type: "success", text: "Cập nhật thành công" });
      reload();
      dispatch({ type: action_type.CLOSE_CONFIRM });
    } else {
      set_notification({ type: "error", text: res.message });
    }
  };
  const commandCellRender = (data) => {
    return (
      <div>
        {can_accessed("api/sis_nhomthanhphandiem/update") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              btnEdit_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon edit"></i>{" "}
          </span>
        )}
        {can_accessed("api/sis_nhomthanhphandiem/delete") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              btnDelete_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon trash"></i>{" "}
          </span>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Transition
        animation="fade right"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <div className="row">
            <div className="col-md-12 padding-top-1rem padding-left-2rem">
              <Toolbar className="bg-background">
                <Item
                  location="before"
                  locateInMenu="never"
                  render={() => {
                    return <h3>Nhóm thành phần điểm</h3>;
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      can_accessed("api/sis_nhomthanhphandiem/insert") && (
                        <PrimaryButton
                          text={"Thêm nhóm"}
                          icon="fas fa-plus"
                          onClick={btnAdd_click}
                        />
                      )
                    );
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <DefaultButton
                        text={"Export"}
                        icon="fas fa-file-export"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      />
                    );
                  }}
                />
              </Toolbar>
            </div>
          </div>
          <DataGrid
            ref={grid}
            keyExpr="id"
            dataSource={sis_nhomthanhphandiems}
            columnHidingEnabled={false}
            columnAutoWidth={true}
            showBorders={true}
            showColumnLines={false}
            showRowLines={false}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            hoverStateEnabled={true}
            focusedRowEnabled={false}
            height={window.innerHeight - 150}
            className="table-noborder-left"
            noDataText="Không có dữ liệu"
          >
            <Sorting mode="multiple" />
            <Paging defaultPageSize={50} />
            <Selection mode="single"></Selection>
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <HeaderFilter visible={true} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50, 200, 500]}
              infoText="Trang {0} /{1} ({2} dòng)"
              showInfo={true}
            />
            <Column caption="Thao tác" cellRender={commandCellRender} />
            <Column dataField="ma_nhom_thanh_phan" caption="Mã nhóm" />
            <Column dataField="ten_nhom_thanh_phan" caption="Tên nhóm" />
            <Column dataField="is_active" caption="Đang sử dụng" />
            <Column dataField="is_locked" caption="Khóa chỉnh sửa" />
          </DataGrid>
        </div>
      </Transition>

      {is_show_detail_modal && (
        <DetailModal
          onCancel={btnCancel_click}
          onSuccess={onSuccess}
          sis_nhomthanhphandiem_id={
            sis_nhomthanhphandiem_selected
              ? sis_nhomthanhphandiem_selected.id
              : 0
          }
        />
      )}
      {is_show_confirm && (
        <Confirm
          open={true}
          dimmer={"blurring"}
          header="Lưu ý"
          content="Bạn có chắc chắn muốn xóa nhóm điểm thành phần này ?"
          size="mini"
          cancelButton={() => {
            return (
              <DefaultButton text="Đóng" onClick={btnCancel_delete_click} />
            );
          }}
          confirmButton={() => {
            return (
              <DangerButton
                icon="fas fa-trash"
                text="Xóa nhóm khoản nộp"
                isLoading={status === status_enum.is_saving}
                onClick={onDelete}
              />
            );
          }}
        />
      )}
    </React.Fragment>
  );
};
