import React, { useMemo, useState, useContext } from "react";
import { Image } from "semantic-ui-react";
import {
  insert as uf_donhangchitiet_insert,
  selct_by_donhang as uf_donhangchitiet_selct_by_donhang,
} from "../../../api/uf_donhangchitiet";
import { StoreContext } from "../../../contexts/store";
export default ({
  data,
  size_list,
  uf_kho,
  ts_hocsinh,
  uf_donhang,
  onAddedItem,
  uf_settieuchuan_id,
  uf_donhangchitiets
}) => {
  const [is_saving, set_is_saving] = useState(false);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const add_item = async (uf_sanphamchitiet, so_luong) => {
    
    if (uf_donhang.ngay_thu_tien) return false;
    if (so_luong > 0 && uf_donhangchitiets.find(x => x.uf_settieuchuan_id != uf_settieuchuan_id) != null) {
      set_notification({ type: "warning", text: "Đơn hàng không cho phép tạo sản phẩm ở các set tiêu chuẩn khác nhau. Bạn cần tạo đơn hàng mua thêm và đơn hàng set tiêu chuẩn riêng." });
      return false;
    }
    if (
      uf_donhang.uf_trangthaidonghang_id == 1 ||
      uf_donhang.uf_trangthaidonghang_id == undefined
    ) {
      set_is_saving(true);
      const res = await uf_donhangchitiet_insert({
        uf_kho_id: uf_kho.id,
        ma_kho: uf_kho.ma_kho,
        ts_hocsinh_id: ts_hocsinh.id,
        uf_donhang_id: uf_donhang.id,
        uf_sanphamchitiet_id: uf_sanphamchitiet.id,
        so_luong: so_luong ? so_luong : 1,
        uf_dongia_id: uf_sanphamchitiet.uf_dongia_id,
        size: uf_sanphamchitiet.size,
        ten_sp: uf_sanphamchitiet.ten_sp,
        uf_settieuchuan_id: uf_settieuchuan_id
      });
      set_is_saving(false);
      if (res.is_success) {
        onAddedItem(res.data);
        set_notification({ type: "success", text: "Cập nhật thành công" });
      } else {
        set_notification({ type: "error", text: res.message });
      }
    } else {
      set_notification({
        type: "warning",
        text: "Đơn hàng đã thu tiền, bạn không thể chỉnh sửa",
      });
    }
  };
  const don_gia_info = useMemo(() => {
    let don_gia_from = 999999999;
    let don_gia_to = 0;
    let don_gia_km_from = 999999999;
    let don_gia_km_to = 0;
    size_list.forEach((uf_sanphamchitiet) => {
      if (uf_sanphamchitiet.don_gia >= don_gia_to) {
        don_gia_to = uf_sanphamchitiet.don_gia;
      }
      if (uf_sanphamchitiet.don_gia <= don_gia_from) {
        don_gia_from = uf_sanphamchitiet.don_gia;
      }
      if (uf_sanphamchitiet.don_gia_khuyen_mai > don_gia_km_to) {
        don_gia_km_to = uf_sanphamchitiet.don_gia_khuyen_mai;
      }
      if (uf_sanphamchitiet.don_gia_khuyen_mai <= don_gia_km_from) {
        don_gia_km_from = uf_sanphamchitiet.don_gia_khuyen_mai;
      }
    });
    return {
      don_gia_from: don_gia_from,
      don_gia_to: don_gia_to,
      don_gia_km_from: don_gia_km_from,
      don_gia_km_to: don_gia_km_to,
    };
  }, [size_list]);
  const { don_gia_from, don_gia_to, don_gia_km_from, don_gia_km_to } =
    don_gia_info;
  const don_gia_text =
    don_gia_from != don_gia_to
      ? `${don_gia_from.toLocaleString()} - ${don_gia_to.toLocaleString()}`
      : don_gia_from.toLocaleString();
  const don_gia_km_text =
    don_gia_km_from != don_gia_km_to
      ? `${don_gia_km_from.toLocaleString()} - ${don_gia_km_to.toLocaleString()}`
      : don_gia_km_from.toLocaleString();
  return (
    <tbody className={`product dx-row`}>
      <tr>
        <td colSpan={3}>
          <div className="product-card">
            <table>
              <tbody>
                <tr className="main-row">
                  <td colSpan={2} width={300} className="product-name-col">
                    <p className="product-name">{data.ten_sp}</p>
                  </td>
                  <td width={100}>
                    {don_gia_text != don_gia_km_text && (
                      <p className="product-const-discount">{don_gia_text}</p>
                    )}
                    <p className="product-const">
                      {"Đơn giá: " + don_gia_km_text}
                    </p>
                  </td>
                </tr>
                <tr className="size-list">
                  <td width={80}>
                    <Image
                      src="/images/image_holder.png"
                      size="medium"
                      rounded
                    />
                  </td>
                  <td colSpan="2" className="product-name-col">
                    <div>
                      {size_list.map((uf_sanphamchitiet) => {
                        return (
                          <label
                            className="p-chip p-chip-big item-size"
                            onDoubleClick={() => {
                              add_item(uf_sanphamchitiet);
                            }}
                          >
                            <input
                              type="checkbox"
                              checked
                              disabled={
                                is_saving || uf_sanphamchitiet.is_out_of_stock
                              }
                            />
                            <span>{uf_sanphamchitiet.size}</span>
                          </label>
                        );
                      })}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  );
};
