export const status_enum = {
  is_loading: "is_loading",
  is_completed: "is_completed",
  is_saving: "is_saving",
};
export const ini_state = {
  is_loading_sanphamchitiets: false,
  is_loading_donhangchitiets: false,
  is_show_confirm_noptien: false,
  uf_sanphamchitiets: [],
  uf_donhang_hinhthucthanhtoans: [],
  uf_donhang: {},
  uf_donhangchitiets: [],
  uf_settieuchuans: [],
  uf_settieuchuan_selected: {},
  uf_settieuchuan_sanphams: []
};
export const action_type = {
  BEGIN_SELECT_SANPHAMCHITIETS: "BEGIN_SELECT_SANPHAMCHITIETS",
  END_SELECT_SANPHAMCHITIETS: "END_SELECT_SANPHAMCHITIETS",

  BEGIN_SELECT_DONHANGCHITIETS: "BEGIN_SELECT_DONHANGCHITIETS",
  END_SELECT_DONHANGCHITIETS: "END_SELECT_DONHANGCHITIETS",

  SET_DONHANGS: "SET_DONHANGS",
  SET_DONHANG_HINHTHUCTHANHTOANS: "SET_DONHANG_HINHTHUCTHANHTOANS",
  BEGIN_SAVING: "BEGIN_SAVING",
  END_SAVING: "END_SAVING",

  SET_SETTIEUCHUANS: "SET_SETTIEUCHUANS",
  SET_SETTIEUCHUAN_SELECTED: "SET_SETTIEUCHUAN_SELECTED",
  SET_SETTIEUCHUAN_SANPHAMS: "SET_SETTIEUCHUAN_SANPHAMS",

  SHOW_CONFIRM_NOPTIEN: "SHOW_CONFIRM_NOPTIEN",
  CLOSE_CONFIRM_NOPTIEN: "CLOSE_CONFIRM_NOPTIEN",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.BEGIN_SELECT_SANPHAMCHITIETS:
      return {
        ...state,
        is_loading_sanphamchitiets: true,
      };
    case action_type.END_SELECT_SANPHAMCHITIETS:
      return {
        ...state,
        is_loading_sanphamchitiets: false,
        uf_sanphamchitiets: action.payload,
      };
    case action_type.SET_DONHANG_HINHTHUCTHANHTOANS:
      return {
        ...state,
        uf_donhang_hinhthucthanhtoans: action.payload,
      };
    case action_type.BEGIN_SELECT_DONHANGCHITIETS:
      return {
        ...state,
        is_loading_donhangchitiets: true,
      };
    case action_type.END_SELECT_DONHANGCHITIETS:
      return {
        ...state,
        is_loading_donhangchitiets: false,
        uf_donhangchitiets: action.payload,
      };
    case action_type.SET_DONHANGS:
      return {
        ...state,
        uf_donhang: action.payload,
      };
    case action_type.SET_SETTIEUCHUANS:
      return {
        ...state,
        uf_settieuchuans: action.payload,
      };
    case action_type.SET_SETTIEUCHUAN_SANPHAMS:
      return {
        ...state,
        uf_settieuchuan_sanphams: action.payload,
      };
    case action_type.SET_SETTIEUCHUAN_SELECTED:
      debugger
      return {
        ...state,
        uf_settieuchuan_selected: action.payload,
      };
    case action_type.BEGIN_SAVING:
      return {
        ...state,
        status: status_enum.is_saving,
      };
    case action_type.END_SAVING:
      return {
        ...state,
        status: status_enum.is_completed,
      };
    case action_type.SHOW_CONFIRM_NOPTIEN:
      return {
        ...state,
        is_show_confirm_noptien: true,
      };
    case action_type.CLOSE_CONFIRM_NOPTIEN:
      return {
        ...state,
        is_show_confirm_noptien: false,
      };

    default:
      return state;
  }
};
