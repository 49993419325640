import { TextBox } from "devextreme-react/text-box";
import { InValidMessage } from "../../../components/editor/invalid_message";
import { Modal, Loader } from "semantic-ui-react";
import React, { useCallback, useEffect, useReducer, useRef, useContext, useMemo } from 'react';
import { select_by_id as uf_donhang_select_by_id } from '../../../api/uf_donhang';
import {
    insert, update, select_by_id
} from '../../../api/uf_phieuchi';
import { useForm } from "react-hook-form";
import { StoreContext } from "../../../contexts/store";
import { action_type, ini_state, reducer, status_enum } from './reducer';
export default ({ uf_donhang_id, uf_phieuchi_id, onSuccess, onCancel }) => {
    const [state, dispatch] = useReducer(reducer, ini_state);
    const { set_notification } = useContext(StoreContext);
    const { register, trigger, errors } = useForm();
    const {
        status,
        uf_donhang,
        uf_phieuchi,
        is_show_confirm_finish
    } = state;


    useEffect(() => {
        if (uf_donhang_id > 0) {
            reload_donhang();
        }
    }, [uf_donhang_id]);
    useEffect(() => {
        if (uf_phieuchi_id > 0) {
            reload_phieuchi();
        }
        dispatch({ type: action_type.SET_PHIEUCHI, payload: { uf_donhang_id: uf_donhang_id } })
    }, [uf_phieuchi_id]);
    const reload_donhang = async () => {
        const res = await uf_donhang_select_by_id(uf_donhang_id);
        if (res.is_success) {
            dispatch({ type: action_type.SET_DONHANG, payload: res.data })

        } else {
            dispatch({ type: action_type.SET_DONHANG, payload: {} })
        }
    }
    const reload_phieuchi = async () => {
        const res = await select_by_id(uf_phieuchi_id);
        if (res.is_success) {
            dispatch({
                type: action_type.SET_PHIEUCHI, payload: res.data
            })
        } else {
            dispatch({ type: action_type.SET_PHIEUCHI, payload: { uf_donhang_id: uf_donhang_id } })
        }
    }
    const handleSaveChanges = async () => {
        dispatch({ type: action_type.BEGIN_SAVING });
        let res;
        debugger
        if (uf_phieuchi_id > 0) {
            res = await update(uf_phieuchi);
        } else {
            res = await insert(uf_phieuchi);
        }
        dispatch({ type: action_type.END_SAVING });
        if (res.is_success) {
            set_notification({ type: "success", text: "Cập nhật thành công" });
            onSuccess();
        } else {
            set_notification({ text: res.message, type: "error" });
        }
    }

    return (
        <React.Fragment>
            <Modal open={true} size="mini" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                height={window.innerHeight - 250}
                className="no-opactiy"
                closeOnDimmerClick={false}>
                <Modal.Header>
                    <p>Tạo phiếu chi cho đơn hàng: <b className="text-info">{uf_donhang.ma_don_hang}</b></p>
                    <p>Số tiền: <b className="text-info">{(uf_donhang.thuc_nop - uf_donhang.phai_nop).toLocaleString()} VNĐ</b></p>
                </Modal.Header>
                <Modal.Content>
                    <form>
                        <div className="row">
                            <div className="col-md-12 padding-top-1rem">
                                <div className="dx-field-label-full">Họ tên người nhận:</div>
                                <br></br>
                                <TextBox
                                    value={uf_phieuchi ? uf_phieuchi.ho_ten_nguoi_nhan : null}
                                    onValueChanged={(e) => {
                                        dispatch({
                                            type: action_type.SET_PHIEUCHI,
                                            payload: {
                                                ...uf_phieuchi,
                                                ho_ten_nguoi_nhan: e.value,
                                            },
                                        });
                                        trigger("ho_ten_nguoi_nhan");
                                    }}
                                    stylingMode="outlined"
                                />
                                {/* <InValidMessage
                                    name="ho_ten_nguoi_nhan"
                                    defaultValue={uf_phieuchi ? uf_phieuchi.ho_ten_nguoi_nhan : null}
                                    register={register({ required: true })}
                                    errors={errors}
                                    message="Vui lòng điền họ tên người nhận"
                                /> */}
                            </div>
                            <div className="col-md-12 padding-top-1rem">
                                <div className="dx-field-label-full">Địa chỉ:</div>
                                <br></br>
                                <TextBox
                                    value={uf_phieuchi ? uf_phieuchi.dia_chi : null}
                                    onValueChanged={(e) => {
                                        dispatch({
                                            type: action_type.SET_PHIEUCHI,
                                            payload: {
                                                ...uf_phieuchi,
                                                dia_chi: e.value,
                                            },
                                        });
                                        trigger("dia_chi");
                                    }}
                                    stylingMode="outlined"
                                />
                                {/* <InValidMessage
                                    name="dia_chi"
                                    defaultValue={uf_phieuchi ? uf_phieuchi.dia_chi : null}
                                    register={register({ required: true })}
                                    errors={errors}
                                    message="Vui lòng điền địa chi"
                                /> */}
                            </div>
                            <div className="col-md-12 padding-top-1rem">
                                <div className="dx-field-label-full">Lý do:</div>
                                <br></br>
                                <TextBox
                                    value={uf_phieuchi ? uf_phieuchi.ly_do : null}
                                    onValueChanged={(e) => {
                                        dispatch({
                                            type: action_type.SET_PHIEUCHI,
                                            payload: {
                                                ...uf_phieuchi,
                                                ly_do: e.value,
                                            },
                                        });
                                        trigger("ly_do");
                                    }}
                                    stylingMode="outlined"
                                />
                                {/* <InValidMessage
                                    name="ly_do"
                                    defaultValue={uf_phieuchi ? uf_phieuchi.ly_do : null}
                                    register={register({ required: true })}
                                    errors={errors}
                                    message="Vui lòng điền lý do"
                                /> */}
                            </div>
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                        <button disabled={status == status_enum.is_saving} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                            {"Cập nhật phiếu chi"}{status == status_enum.is_saving && <Loader active inline size="mini" />}
                        </button>

                    </div>
                </Modal.Actions>
            </Modal >

        </React.Fragment>
    );
}