export const status_enum = {
  is_loading: 'is_loading',
  is_completed: 'is_completed',
  is_saving: 'is_saving',
};
export const ini_state = {
  status: status_enum,
  uf_nhapkhos: [],
  uf_nhapkho_selected: {},

  uf_sanphamchitiets: [],
  uf_khos: [],
  is_show_confirm: false,
  is_show_detail_modal: false,
  is_show_import_modal: false
};
export const action_type = {
  BEGIN_SELECT_NHAPKHOS: 'BEGIN_SELECT_NHAPKHOS',
  END_SELECT_NHAPKHOS: 'END_SELECT_NHAPKHOS',

  SHOW_CONFIRM: 'SHOW_CONFIRM',
  CLOSE_CONFIRM: 'CLOSE_CONFIRM',

  SHOW_DETAIL_MODAL: 'SHOW_DETAIL_MODAL',
  CLOSE_DETAIL_MODAL: 'CLOSE_DETAIL_MODAL',

  BEGIN_SAVING: 'BEGIN_SAVING',
  END_SAVING: 'END_SAVING',

  SET_SANPHAMCHITIETS: "SET_SANPHAMCHITIETS",
  SET_KHOS: "SET_KHOS",

  SHOW_IMPORT_MODAL: 'SHOW_IMPORT_MODAL',
  CLOSE_IMPORT_MODAL: 'CLOSE_IMPORT_MODAL',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.BEGIN_SELECT_NHAPKHOS:
      return {
        ...state,
        status: status_enum.is_loading,
      };
    case action_type.END_SELECT_NHAPKHOS:
      return {
        ...state,
        status: status_enum.is_completed,
        uf_nhapkhos: action.payload,
      };
    case action_type.SET_KHOS:
      return {
        ...state,
        uf_khos: action.payload
      };
    case action_type.SET_SANPHAMCHITIETS:
      return {
        ...state,
        uf_sanphamchitiets: action.payload
      };

    case action_type.SHOW_CONFIRM:
      return {
        ...state,
        uf_nhapkho_selected: action.payload,
        is_show_confirm: true,
      };


    case action_type.CLOSE_CONFIRM:
      return {
        ...state,
        is_show_confirm: false,
      };


    case action_type.SHOW_DETAIL_MODAL:
      return {
        ...state,
        uf_nhapkho_selected: action.payload,
        is_show_detail_modal: true,
      };

    case action_type.CLOSE_DETAIL_MODAL:
      return {
        ...state,
        is_show_detail_modal: false,
      };
    case action_type.BEGIN_SAVING:
      return {
        ...state,
        status: status_enum.is_saving,
      };

    case action_type.END_SAVING:
      return {
        ...state,
        status: status_enum.is_completed,
      };


    case action_type.SHOW_IMPORT_MODAL:
      return {
        ...state,
        is_show_import_modal: true
      };
    case action_type.CLOSE_IMPORT_MODAL:
      return {
        ...state,
        is_show_import_modal: false
      };

    default:
      return state;
  }
};
