import DataGrid, {
    Column,
    Editing, Pager,
    Paging, Sorting
} from "devextreme-react/data-grid";
import { Modal, Loader } from "semantic-ui-react";
import React, { useCallback, useEffect, useReducer, useRef, useContext, useMemo } from 'react';
import { select_by_id as uf_donhang_select_by_id } from '../../../api/uf_donhang';
import {
    selct_by_donhang as uf_donhangchitiet_selct_by_donhang,
    doitra as uf_donhangchitiet_doitra
} from '../../../api/uf_donhangchitiet';
import { StoreContext } from "../../../contexts/store";
import { action_type, ini_state, reducer, status_enum } from './reducer';
export default ({ uf_donhang_id, onCancel }) => {
    const [state, dispatch] = useReducer(reducer, ini_state);
    const grid = useRef(null)
    const { set_notification } = useContext(StoreContext);
    const {
        status,
        uf_donhang,
        uf_donhangchitiets,
        is_show_confirm_finish
    } = state;
    const total_doitra = useMemo(() => {
        return uf_donhangchitiets.map(x => { return x.doi_tra }).reduce((a, b) => a + b, 0);
    }, [uf_donhangchitiets])
    useEffect(() => {
        if (status === status_enum.is_loading) {
            grid.current.instance.beginCustomLoading();
        } else {
            grid.current.instance.endCustomLoading();
        }
    }, [status]);
    useEffect(() => {

        if (uf_donhang_id > 0) {
            reload_donhang()
        }
    }, [uf_donhang_id]);
    const reload_donhang = useCallback(async () => {
        const res = await uf_donhang_select_by_id(uf_donhang_id);
        if (res.is_success) {
            dispatch({ type: action_type.SET_DONHANG, payload: res.data })
            reload_donhangchitiets();
        } else {
            dispatch({ type: action_type.SET_DONHANG, payload: {} })
        }
    }, [uf_donhang_id]);
    const reload_donhangchitiets = useCallback(async () => {
        dispatch({ type: action_type.BEGIN_SELECT_DONHANGCHITIETS })
        const res = await uf_donhangchitiet_selct_by_donhang(uf_donhang_id);
        if (res.is_success) {
            dispatch({
                type: action_type.END_SELECT_DONHANGCHITIETS, payload: res.data.map(x => {
                    return { ...x, con_lai: x.so_luong - x.so_luong_doi_tra, doi_tra: 0 }
                })
            })
        } else {
            dispatch({ type: action_type.END_SELECT_DONHANGCHITIETS, payload: [] })
        }
    }, [uf_donhang_id])
    const handleSaveChanges = async () => {
        // const total = uf_donhangchitiets.map(x => { return x.doi_tra }).reduce((a, b) => a + b, 0);
        dispatch({ type: action_type.BEGIN_SAVING })
        if (total_doitra <= 0) {
            set_notification({ type: "warning", text: "Bạn chưa điền số lượng trả" });
        } else {
            const res = await uf_donhangchitiet_doitra(uf_donhangchitiets.filter(x => x.doi_tra > 0));
            dispatch({ type: action_type.END_SAVING })
            if (res.is_success) {
                set_notification({ type: "success", text: "Đã trả sản phẩm thành công" });
                onCancel();
            } else {
                set_notification({ type: "error", text: "Có lỗi: " + res.message });
            }
        }
    }
    const so_luong_setCellValue = (newData, value, currentRowData) => {
        if (value <= currentRowData.con_lai) {
            newData.doi_tra = value;
            const new_state = uf_donhangchitiets.map(x => {
                if (x.id != currentRowData.id) {
                    return x;
                } else {
                    return { ...x, doi_tra: value }
                }
            })
            dispatch({ type: action_type.END_SELECT_DONHANGCHITIETS, payload: new_state })
        } else {
            set_notification({ type: "warning", text: "Số lượng trả không được vượt quá số lượng đã mua" });
        }
    }
    return (
        <React.Fragment>
            <Modal open={true} size="large" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                height={window.innerHeight - 250}
                className="no-opactiy"
                closeOnDimmerClick={false}>
                <Modal.Header>
                    Đổi trả sản phẩm
                </Modal.Header>
                <Modal.Content>

                    <div className="row">
                        <div className="col-md-12">
                            <DataGrid
                                ref={grid}
                                keyExpr="id"
                                dataSource={uf_donhangchitiets}
                                columnHidingEnabled={false}
                                columnAutoWidth={true}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode={"nextColumn"}
                                hoverStateEnabled={false}
                                focusedRowEnabled={false}
                                height={window.innerHeight - 350}
                                noDataText="Không có dữ liệu"
                                wordWrapEnabled={true}
                            >
                                <Sorting mode="multiple" />
                                <Editing mode="cell" allowUpdating={true}></Editing>
                                <Paging defaultPageSize={50} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[10, 20, 50, 200, 500]}
                                    infoText="Trang {0} /{1} ({2} dòng)"
                                    showInfo={true}
                                />
                                <Column
                                    dataField="ten_sp"
                                    caption="Tên sản phẩm"
                                    allowEditing={false}
                                    cellRender={(data) => {
                                        return <p className="product-name">{data.data.ten_sp}</p>;
                                    }}
                                />
                                <Column
                                    dataField="size"
                                    allowEditing={false}
                                    caption="Size"
                                    width={90}
                                />
                                <Column
                                    dataField="so_luong"
                                    caption="Số lượng mua"
                                    format=",##0"
                                    width={80}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField="so_luong_doi_tra"
                                    caption="Số lượng đã đổi trả"
                                    format=",##0"
                                    width={80}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField="con_lai"
                                    caption="Còn lại"
                                    format=",##0"
                                    width={80}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField="don_gia"
                                    caption="Đơn giá"
                                    format=",##0"
                                    width={80}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField="doi_tra"
                                    caption="Số lượng trả"
                                    headerCellRender={(cell) => {
                                        return <p className="text-danger">Số lượng trả</p>
                                    }}
                                    format=",##0"
                                    width={120}
                                    dataType="numeric"
                                    setCellValue={so_luong_setCellValue}
                                    allowEditing={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                        <button disabled={status == status_enum.is_saving || total_doitra <= 0} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                            {"Cập nhật"}{status == status_enum.is_saving && <Loader active inline size="mini" />}
                        </button>

                    </div>
                </Modal.Actions>
            </Modal >

        </React.Fragment>
    );
}