import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { LoginForm } from './components';
export default function () {
  return (
    <Switch>
      <Route exact path='/login' >
        <LoginForm />
      </Route>

      <Redirect to={'/login'} />
    </Switch>
  );
}
