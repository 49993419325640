import React, { useState, useEffect } from "react";
import TagBox from "devextreme-react/tag-box";
import { select_all } from "../../api/uf_trangthaidonhang";
export default ({ value, onValueChanged }) => {
  const [dm_trangthaidonhangs, set_dm_trangthaidonhangs] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await select_all();
      if (res.is_success) {
        set_dm_trangthaidonhangs(res.data);
      } else {
        set_dm_trangthaidonhangs([]);
      }
    })();
  }, []);
  return (
    <TagBox
      items={dm_trangthaidonhangs}
      searchEnabled={true}
      displayExpr={"trang_thai"}
      valueExpr={"id"}
      stylingMode="outlined"
      placeholder="Trạng thái đơn hàng"
      multiline={false}
      showSelectionControls={true}
      applyValueMode="instantly"
      width={200}
      value={value !== undefined ? value : []}
      onValueChanged={(e) => {
        onValueChanged(e.value);
      }}
    />
  );
};
