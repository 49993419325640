import DataGrid, {
    Column, FilterRow,
    HeaderFilter, Pager,
    Paging,
    Selection, Sorting
} from "devextreme-react/data-grid";
import React, { useContext, useEffect, useMemo, useReducer, useRef } from "react";
import { Modal } from "semantic-ui-react";
import { import_data, read_import_file } from "../../../api/uf_nhapkho";
import {
    DefaultButton, PrimaryButton
} from "../../../components/editor/button";
import UploadExcel from '../../../components/file-upload/UploadExcel';
import { StoreContext } from "../../../contexts/store";
import { action_type, ini_state, reducer, status_enum } from "./reducer";


export default ({ onCancel, onSuccess }) => {
    const [state, dispatch] = useReducer(reducer, ini_state);
    const { set_notification, can_accessed } = useContext(StoreContext);
    const grid = useRef(null);
    const {
        status,
        file_name,
        data_source
    } = state;
    useEffect(() => {
        if (file_name) {
            read_data();
        }
    }, [file_name]);
    useEffect(() => {
        if (status === status_enum.is_loading) {
            grid.current.instance.beginCustomLoading();
        } else {
            grid.current.instance.endCustomLoading();
        }
    }, [status]);
    const errr_code = useMemo(() => {
        const count = data_source.filter(x => !x.is_valid).length;
        if (count > 0) {
            return `Có ${count} dòng không hợp lệ, vui lòng kiểm tra lại dữ liệu`;
        }
        return "";
    }, [data_source]);
    const read_data = async () => {
        dispatch({ type: action_type.BEGIN_SELECT_DATA });
        const res = await read_import_file({ file_name: file_name, sheet_index: 0 });
        if (res.is_success) {
            dispatch({ type: action_type.END_SELECT_DATA, payload: res.data });
        } else {
            set_notification({ type: "error", text: res.message });
        }
    }
    const handleSaveChanges = async () => {
        dispatch({ type: action_type.BEGIN_SAVING });
        const res = await import_data(data_source);
        if (res.is_success) {
            dispatch({ type: action_type.END_SAVING });
            set_notification({ type: "success", text: "Import thành công" });
            onSuccess();
        } else {
            set_notification({ type: "error", text: res.message });
        }
    };
    return (
        <React.Fragment>
            <Modal
                open={true}
                size="large"
                dimmer={"blurring"}
                onClose={onCancel}
                closeOnEscape={false}
                closeOnDimmerClick={false}
            >
                <Modal.Header>
                    Import dữ liệu từ excel
                </Modal.Header>
                <Modal.Content>
                    <div className="row">
                        <div className="col-md-6">

                            <UploadExcel
                                onSuccess={(filename) => {
                                    dispatch({ type: action_type.CHANGE_FILENAME, payload: filename })
                                }}
                                onError={(message) => {
                                    set_notification({ type: "error", text: message });
                                }}
                            />
                            {errr_code && <p className="text-danger padding-top-1rem">{errr_code}</p>}

                        </div>
                        <div className="col-md-6 text-align-right" >
                            <a href="../TEMPLATE/Import_nhap_kho.xlsx" target="_blank">Tải file mẫu</a>
                        </div>
                        <div className="col-md-12 padding-top-1rem">
                            <DataGrid
                                ref={grid}
                                keyExpr="id"
                                dataSource={data_source}
                                columnHidingEnabled={false}
                                columnAutoWidth={true}
                                showBorders={true}
                                showColumnLines={false}
                                showRowLines={false}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode={"nextColumn"}
                                hoverStateEnabled={true}
                                focusedRowEnabled={false}
                                height={errr_code ? window.innerHeight - 300 : window.innerHeight - 250}
                                noDataText="Không có dữ liệu"
                                wordWrapEnabled={true}
                            >
                                <Selection mode="single" />
                                <Sorting mode="multiple" />
                                <Paging defaultPageSize={50} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[10, 20, 50, 200, 500]}
                                    infoText="Trang {0} /{1} ({2} dòng)"
                                    showInfo={true}
                                />
                                <Column dataField="id" caption="TT" />
                                <Column dataField="is_valid" caption="Hợp lệ" />
                                <Column dataField="error" caption="Mã lỗi" />
                                <Column dataField="ma_san_pham" caption={"Mã sản phẩm"} />
                                <Column dataField="ten_san_pham" caption={"Tên sản phẩm"} />
                                <Column dataField="ma_kho" caption="Mã kho" />
                                <Column dataField="ten_kho" caption="Tên kho" />
                                <Column dataField="ngay_nhap" caption="Ngày nhập" format="dd/MM/yyyy" dataType="date" />
                                <Column dataField="so_luong" caption="Số lượng" format=",##0" />
                                <Column dataField="ghi_chu" caption="Ghi chú" />
                            </DataGrid>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <DefaultButton onClick={onCancel} text="Đóng" />
                        {file_name && !errr_code && <PrimaryButton
                            onClick={handleSaveChanges}
                            text="Import"
                            disabled={status === status_enum.is_saving}
                            isLoading={status === status_enum.is_saving}
                            icon="fas fa-check"
                        />}

                    </div>
                </Modal.Actions>
            </Modal>
        </React.Fragment>
    );
};
