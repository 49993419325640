import appInfo from '../app-info';
import { call_post_api } from './api_helper';

export const select_all = async () => {
  return call_post_api(appInfo.api_url + '/uf_nhapkho/select_all');
};
export const insert = async (data) => {
  return call_post_api(appInfo.api_url + '/uf_nhapkho/insert', data);
};
export const update = async (data) => {
  return call_post_api(appInfo.api_url + '/uf_nhapkho/update', data);
};
export const remove = async (data) => {
  return call_post_api(appInfo.api_url + '/uf_nhapkho/delete', data);
};
export const select_by_id = async (id) => {
  return call_post_api(appInfo.api_url + '/uf_nhapkho/select_by_id', id);
};
export const read_import_file = async (data) => {
  return call_post_api(appInfo.api_url + '/uf_nhapkho/read_import_file', data);
};
export const import_data = async (data) => {
  return call_post_api(appInfo.api_url + '/uf_nhapkho/import_data', data);
};
