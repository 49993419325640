export const status_enum = {
  is_loading: 'is_loading',
  is_completed: 'is_completed',
  is_saving: 'is_saving',
};
export const ini_state = {
  status: status_enum,
  uf_dongia: {},
  uf_products: [],
  uf_khos: [],
};
export const action_type = {
  BEGIN_SELECT_DONGIA: 'BEGIN_SELECT_DONGIA',
  END_SELECT_DONGIA: 'END_SELECT_DONGIA',

  BEGIN_SELECT_PRODUCTS: 'BEGIN_SELECT_PRODUCTS',
  END_SELECT_PRODUCTS: 'END_SELECT_PRODUCTS',

  BEGIN_SELECT_KHOS: 'BEGIN_SELECT_KHOS',
  END_SELECT_KHOS: 'END_SELECT_KHOS',

  CHANGE_DONGIA: 'CHANGE_DONGIA',

  BEGIN_SAVING: 'BEGIN_SAVING',
  END_SAVING: 'END_SAVING',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.BEGIN_SELECT_DONGIA:
      return {
        ...state,
        status: status_enum.is_loading,
      };
    case action_type.END_SELECT_DONGIA:
      return {
        ...state,
        status: status_enum.is_completed,
        uf_dongia: action.payload,
      };
    case action_type.BEGIN_SELECT_PRODUCTS:
      return {
        ...state,
        status: status_enum.is_loading,
      };
    case action_type.END_SELECT_PRODUCTS:
      return {
        ...state,
        status: status_enum.is_completed,
        uf_products: action.payload,
      };
    case action_type.BEGIN_SELECT_KHOS:
      return {
        ...state,
        status: status_enum.is_loading,
      };
    case action_type.END_SELECT_KHOS:
      return {
        ...state,
        status: status_enum.is_completed,
        uf_khos: action.payload,
      };

    case action_type.CHANGE_DONGIA:
      return {
        ...state,
        uf_dongia: action.payload,
      };

    case action_type.BEGIN_SAVING:
      return {
        ...state,
        status: status_enum.is_saving,
      };

    case action_type.END_SAVING:
      return {
        ...state,
        status: status_enum.is_completed,
      };

    default:
      return state;
  }
};
