import React, { useState, useEffect } from "react";
import SelectBox from "devextreme-react/select-box";
import { select_all } from "../../api/uf_kho";
export default ({ value, onValueChanged, cmb }) => {
  const [dm_khos, set_dm_khos] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await select_all();
      if (res.is_success) {
        set_dm_khos(res.data);
      } else {
        set_dm_khos([]);
      }
    })();
  }, []);
  return (
    <SelectBox
      dataSource={dm_khos}
      displayExpr={"ten_kho"}
      valueExpr={"id"}
      showClearButton={true}
      placeholder="Chọn kho"
      stylingMode="outlined"
      value={value !== undefined ? value : ""}
      ref={cmb}
      onValueChanged={(e) => {
        onValueChanged(e.value);
      }}
    />
  );
};
