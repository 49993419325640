import React, { useEffect, useRef, useCallback, useContext } from "react";
import TreeView from "devextreme-react/tree-view";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import "./side-navigation-menu.scss";
import * as events from "devextreme/events";
import { StoreContext } from "../../contexts/store";
import { Link } from "react-router-dom";

export default function (props) {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props;
  //const { user, loading } = useAuth();
  // const [items, setItems] = useState([]);
  const { isLarge } = useScreenSize();
  const { menu_items } = useContext(StoreContext);

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);
  //const items = useMemo(normalizePath, [user.sys_menus]);
  const renderTreeViewItem = (item) => {
    //return JSON.stringify(item);
    return (
      <Link to={item.path} className="menu-item">
        {item.icon !== "" && <i className={item.icon}></i>}
        <span>{item.name}</span>
      </Link>
    );
  };

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
      {children}
      <div className={"menu-container menu-treeview"}>
        <TreeView
          ref={treeViewRef}
          items={menu_items}
          keyExpr={"path"}
          displayExpr="name"
          selectionMode="single"
          selectByClick={true}
          focusStateEnabled={true}
          selectNodesRecursive={false}
          expandEvent={"click"}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={"100%"}
          itemRender={renderTreeViewItem}
        />
      </div>
    </div>
  );
}
