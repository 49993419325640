export const status_enum = {
  is_loading: "is_loading",
  is_completed: "is_completed",
  is_saving: "is_saving",
};
export const ini_state = {
  ly_do_huy: "",
};
export const action_type = {
  CHANGE_LY_DO_HUY: "CHANGE_LY_DO_HUY",

  BEGIN_SAVING: "BEGIN_SAVING",
  END_SAVING: "END_SAVING",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.CHANGE_LY_DO_HUY:
      return {
        ...state,
        ly_do_huy: action.payload,
      };
    case action_type.BEGIN_SAVING:
      return {
        ...state,
        status: status_enum.is_saving,
      };

    case action_type.END_SAVING:
      return {
        ...state,
        status: status_enum.is_completed,
      };
    default:
      return state;
  }
};
