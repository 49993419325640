import React, { useEffect, useReducer, useContext } from "react";
import { status_enum, action_type, ini_state, reducer } from "./reducer";
import { Modal } from "semantic-ui-react";
import { TextBox } from "devextreme-react/text-box";
import {
  PrimaryButton,
  DefaultButton,
} from "../../../components/editor/button";
import { InValidMessage } from "../../../components/editor/invalid_message";

import { StoreContext } from "../../../contexts/store";
import { useForm } from "react-hook-form";

import { select_by_id, insert, update } from "../../../api/uf_dongia";
import { NumberBox, SelectBox } from "devextreme-react";
import { select_all as select_all_products } from "../../../api/uf_sanpham";
import { select_all as select_all_kho } from "../../../api/uf_kho";
import dxNumberBox from "devextreme/ui/number_box";

export default ({ uf_dongia_id, onCancel, onSuccess }) => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const { register, trigger, errors } = useForm();
  const { status, uf_dongia, uf_products, uf_khos } = state;
  useEffect(() => {
    if (uf_dongia_id > 0) {
      request_select_by_id();
    } else {
      dispatch({ type: action_type.CHANGE_DONGIA, payload: {} });
    }
    get_all_products();
    get_all_khos();
  }, [uf_dongia_id]);
  const request_select_by_id = async () => {
    dispatch({ type: action_type.BEGIN_SELECT_DONGIA });
    const res = await select_by_id(uf_dongia_id);
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_DONGIA,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.END_SELECT_DONGIA, payload: {} });
      set_notification({ type: "error", text: res.message });
    }
  };

  const get_all_products = async () => {
    dispatch({ type: action_type.BEGIN_SELECT_PRODUCTS });
    const res = await select_all_products();
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_PRODUCTS,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.END_SELECT_PRODUCTS, payload: [] });
      set_notification({ type: "error", text: res.message });
    }
  };
  const get_all_khos = async () => {
    dispatch({ type: action_type.BEGIN_SELECT_KHOS });
    const res = await select_all_kho();
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_KHOS,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.END_SELECT_KHOS, payload: [] });
      set_notification({ type: "error", text: res.message });
    }
  };

  const handleSaveChanges = async () => {
    trigger().then(async (isValid) => {
      if (isValid) {
        dispatch({ type: action_type.BEGIN_SAVING });
        let res;
        if (uf_dongia_id > 0) {
          res = await update(uf_dongia);
        } else {
          res = await insert(uf_dongia);
        }
        dispatch({ type: action_type.END_SAVING });
        if (res.is_success) {
          set_notification({ type: "success", text: "Cập nhật thành công" });
          onSuccess();
        } else {
          set_notification({ text: res.message, type: "error" });
        }
      }
    });
  };
  return (
    <React.Fragment>
      <Modal
        open={true}
        size="mini"
        dimmer={"blurring"}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {uf_dongia_id > 0 ? <p>Cập nhật</p> : <p>Thêm mới</p>}
        </Modal.Header>
        <Modal.Content>
          <form className="ui">
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Kho hàng</div>
                <br></br>
                <SelectBox
                  dataSource={uf_khos}
                  displayExpr={"ten_kho"}
                  valueExpr={"id"}
                  placeholder="Chọn kho hàng"
                  searchEnabled="true"
                  value={uf_dongia.uf_kho_id}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_DONGIA,
                      payload: {
                        ...uf_dongia,
                        uf_kho_id: e.value,
                      },
                    });
                    trigger("kho_hang");
                  }}
                />
                <InValidMessage
                  name="kho_hang"
                  defaultValue={uf_dongia.uf_kho_id}
                  register={register({ required: true })}
                  errors={errors}
                  message="Vui lòng chọn kho hàng"
                />
              </div>
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Sản phẩm</div>
                <br></br>
                <SelectBox
                  dataSource={uf_products.map(x => {
                    return {
                      ...x,
                      ten_sp: '(' + x.ma_sp + ') ' + x.ten_sp
                    }
                  })}
                  displayExpr={"ten_sp"}
                  valueExpr={"id"}
                  placeholder="Chọn sản phẩm"
                  searchEnabled="true"
                  value={uf_dongia.uf_sanpham_id}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_DONGIA,
                      payload: {
                        ...uf_dongia,
                        uf_sanpham_id: e.value,
                      },
                    });
                    trigger("san_pham");
                  }}
                />
                <InValidMessage
                  name="san_pham"
                  defaultValue={uf_dongia.uf_sanpham_id}
                  register={register({ required: true })}
                  errors={errors}
                  message="Vui lòng chọn sản phẩm"
                />
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label-full">Size sản phẩm</div>
                <br></br>
                <TextBox
                  value={uf_dongia.size}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_DONGIA,
                      payload: {
                        ...uf_dongia,
                        size: e.value,
                      },
                    });
                    trigger("size");
                  }}
                />

                <InValidMessage
                  name="size"
                  defaultValue={uf_dongia.size}
                  register={register({ required: true })}
                  errors={errors}
                  message="Vui lòng điền size sản phẩm"
                />
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label-full">Mã item</div>
                <br></br>
                <TextBox
                  value={uf_dongia.ma_sp_chitiet}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_DONGIA,
                      payload: {
                        ...uf_dongia,
                        ma_sp_chitiet: e.value,
                      },
                    });
                    trigger("ma_sp_chitiet");
                  }}
                />

                <InValidMessage
                  name="ma_sp_chitiet"
                  defaultValue={uf_dongia.ma_sp_chitiet}
                  register={register({ required: true })}
                  errors={errors}
                  message="Vui lòng điền mã item"
                />
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label-full">Đơn giá</div>
                <br></br>
                <NumberBox
                  value={uf_dongia.don_gia}
                  stylingMode="outlined"
                  format=",##0"
                  type="number"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_DONGIA,
                      payload: {
                        ...uf_dongia,
                        don_gia: e.value,
                      },
                    });
                    trigger("dongia");
                  }}
                />
                <InValidMessage
                  name="dongia"
                  defaultValue={uf_dongia.don_gia}
                  register={register({ required: true, min: 1 })}
                  errors={errors}
                  message="Vui lòng nhập đơn giá"
                />
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label-full">Đơn giá khuyến mại</div>
                <br></br>
                <NumberBox
                  value={uf_dongia.don_gia_khuyen_mai}
                  stylingMode="outlined"
                  type="number"
                  format=",##0"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_DONGIA,
                      payload: {
                        ...uf_dongia,
                        don_gia_khuyen_mai: e.value,
                      },
                    });
                    trigger("don_gia_khuyen_mai");
                  }}
                />
                <InValidMessage
                  name="don_gia_khuyen_mai"
                  defaultValue={uf_dongia.don_gia_khuyen_mai}
                  register={register({ required: true, min: 1 })}
                  errors={errors}
                  message="Vui lòng nhập đơn giá khuyến mại"
                />
              </div>
              {/* <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label-full">Số lượng tồn</div>
                <br></br>
                <NumberBox
                  value={uf_dongia.so_luong_ton}
                  stylingMode="outlined"
                  format=",##0"
                  type="number"
                  onValueChanged={(e) => {
                    dispatch({
                      type: action_type.CHANGE_DONGIA,
                      payload: {
                        ...uf_dongia,
                        so_luong_ton: e.value,
                      },
                    });
                    trigger("so_luong_ton");
                  }}
                />
                <InValidMessage
                  name="so_luong_ton"
                  defaultValue={uf_dongia.so_luong_ton}
                  register={register({ required: true, min: 1 })}
                  errors={errors}
                  message="Vui lòng nhập Số lượng tồn"
                />
              </div> */}
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <DefaultButton onClick={onCancel} text="Đóng" />
            <PrimaryButton
              onClick={handleSaveChanges}
              text={uf_dongia_id > 0 ? "Cập nhật" : "Thêm mới"}
              disabled={status === status_enum.is_saving}
              isLoading={status === status_enum.is_saving}
              icon="fas fa-check"
            />
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};
