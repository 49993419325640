import React, { useState, useEffect } from "react";
import SelectBox from "devextreme-react/select-box";
import {
  select_all as dm_he_select_all,
  select_by_khoi as dm_he_select_by_khoi,
} from "../../api/dm_he";
import {
  select_all as dm_khoi_select_all,
  select_by_truong as dm_khoi_select_by_truong,
} from "../../api/dm_khoi";
import { select_all as dm_truong_select_all } from "../../api/dm_truong";
import { select_all as dm_namhoc_select_all } from "../../api/dm_namhoc";
import { select_all as dm_category_select_all } from "../../api/uf_category";
import { select_all as uf_kho_select_all } from "../../api/uf_kho";
const ComboboxHe = ({
  value,
  onValueChanged,
  cmb,
  dm_khoi_id,
  showClearButton,
  is_readonly,
}) => {
  const [dm_hes, set_dm_hes] = useState([]);
  useEffect(() => {
    (async () => {
      let res = null;
      if (dm_khoi_id > 0) {
        res = await dm_he_select_by_khoi(dm_khoi_id);
      } else {
        res = await dm_he_select_all();
      }
      if (res.is_success) {
        set_dm_hes(res.data);
      } else {
        set_dm_hes([]);
      }
    })();
  }, [dm_khoi_id]);
  return (
    <SelectBox
      dataSource={dm_hes}
      displayExpr={"ten_he"}
      valueExpr={"id"}
      value={value}
      stylingMode="outlined"
      ref={cmb}
      readOnly={is_readonly === true ? is_readonly : false}
      placeholder="Chọn hệ"
      width="100%"
      showClearButton={showClearButton}
      onValueChanged={(e) => {
        onValueChanged(e.value);
      }}
    />
  );
};

const ComboboxKhoi = ({
  value,
  onValueChanged,
  cmb,
  dm_truong_id,
  showClearButton,
  is_readonly,
}) => {
  const [dm_khois, set_dm_khois] = useState([]);

  useEffect(() => {
    (async () => {
      let res = null;
      if (dm_truong_id > 0) {
        res = await dm_khoi_select_by_truong(dm_truong_id);
      } else {
        res = await dm_khoi_select_all();
      }
      if (res.is_success) {
        set_dm_khois(res.data);
      } else {
        set_dm_khois([]);
      }
    })();
  }, [dm_truong_id]);
  return (
    <SelectBox
      dataSource={dm_khois}
      displayExpr={"ten_khoi"}
      valueExpr={"id"}
      value={value}
      readOnly={is_readonly === true ? is_readonly : false}
      stylingMode="outlined"
      ref={cmb}
      placeholder="Chọn khối"
      width={120}
      showClearButton={showClearButton}
      onValueChanged={(e) => {
        onValueChanged(e.value);
      }}
    />
  );
};

const ComboboxTruong = ({
  value,
  onValueChanged,
  cmb,
  showClearButton,
  is_readonly,
}) => {
  const [dm_truongs, set_dm_truongs] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await dm_truong_select_all();
      if (res.is_success) {
        set_dm_truongs(res.data);
      } else {
        set_dm_truongs([]);
      }
    })();
  }, []);
  return (
    <SelectBox
      dataSource={dm_truongs}
      displayExpr={"ten_truong"}
      valueExpr={"id"}
      value={value}
      stylingMode="outlined"
      ref={cmb}
      readOnly={is_readonly === true ? is_readonly : false}
      placeholder="Chọn trường"
      width="100%"
      showClearButton={showClearButton}
      onValueChanged={(e) => {
        onValueChanged(e.value);
      }}
    />
  );
};

const ComboboxNamHoc = ({ value, onValueChanged, cmb }) => {
  const [dm_namhocs, set_dm_namhocs] = useState([]);
  const [namhoc_default, set_namhoc_default] = useState("");
  useEffect(() => {
    (async () => {
      const res = await dm_namhoc_select_all();
      if (res.is_success) {
        set_dm_namhocs(res.data);
        const temp = res.data.filter((x) => x.is_default);
        if (temp.length > 0) {
          set_namhoc_default(temp[0].nam_hoc);
          if (value === undefined) {
            onValueChanged(temp[0].nam_hoc);
          }
        }
      } else {
        set_dm_namhocs([]);
        set_namhoc_default("");
      }
    })();
  }, []);
  return (
    <SelectBox
      dataSource={dm_namhocs}
      displayExpr={"nam_hoc"}
      valueExpr={"nam_hoc"}
      placeholder="Chọn năm học"
      value={value !== undefined ? value : namhoc_default}
      stylingMode="outlined"
      width={120}
      ref={cmb}
      onValueChanged={(e) => {
        onValueChanged(e.value);
      }}
    />
  );
};

const ComboboxCategory = ({ value, onValueChanged, cmb }) => {
  const [dm_categories, set_dm_categories] = useState([]);
  const [category_default, set_category_default] = useState("");
  useEffect(() => {
    (async () => {
      const res = await dm_category_select_all();
      if (res.is_success) {
        set_dm_categories(res.data);
        const temp = res.data.filter((x) => x.is_default);
        if (temp.length > 0) {
          set_category_default(temp[0].id);
          if (value === undefined) {
            onValueChanged(temp[0].id);
          }
        }
      } else {
        set_dm_categories([]);
        set_category_default("");
      }
    })();
  }, []);
  return (
    <SelectBox
      dataSource={dm_categories}
      displayExpr={"category"}
      valueExpr={"id"}
      placeholder="Chọn nhóm sản phẩm"
      value={value !== undefined ? value : category_default}
      stylingMode="outlined"
      width={120}
      ref={cmb}
      onValueChanged={(e) => {
        onValueChanged(e.value);
      }}
    />
  );
};

const ComboboxKho = ({ value, onValueChanged, cmb }) => {
  const [uf_khos, set_uf_khos] = useState([]);
  const [uf_kho_id, set_uf_kho_id] = useState(0);
  useEffect(() => {
    (async () => {
      const res = await uf_kho_select_all();
      if (res.is_success) {
        const data = res.data.map((x) => {
          return { ...x, ten_kho: `[${x.ma_kho}] ${x.ten_kho}` };
        });
        set_uf_khos(data);
        
        if (value == undefined) {
          const uf_kho_id_cached = isNaN(parseInt(localStorage.uf_kho_id))
            ? 0
            : parseInt(localStorage.uf_kho_id);
          if (uf_kho_id_cached > 0) {
            const filter_result = data.filter((x) => x.id == uf_kho_id_cached);
            onValueChanged(
              uf_kho_id_cached,
              filter_result.length > 0 ? filter_result[0] : null
            );
            //onValueChanged(uf_kho_id_cached);
          }
        } else {
          set_uf_kho_id(value);
        }
      } else {
        set_uf_khos([]);
      }
    })();
  }, []);
  return (
    <SelectBox
      dataSource={uf_khos}
      displayExpr={"ten_kho"}
      valueExpr={"id"}
      placeholder="Chọn kho"
      value={value !== undefined ? value : uf_kho_id}
      stylingMode="outlined"
      width={200}
      ref={cmb}
      onValueChanged={(e) => {
        const filter_result = uf_khos.filter((x) => x.id == e.value);

        onValueChanged(
          e.value,
          filter_result.length > 0 ? filter_result[0] : null
        );
      }}
    />
  );
};
export {
  ComboboxHe,
  ComboboxKhoi,
  ComboboxTruong,
  ComboboxNamHoc,
  ComboboxCategory,
  ComboboxKho,
};
