import { NumberBox } from 'devextreme-react/number-box';
import { TextBox } from 'devextreme-react/text-box';
import React, { useContext, useEffect, useReducer, version } from "react";
import { Card, Image, Loader, Modal } from 'semantic-ui-react';
import { select_all as sf_hinhthucthanhtoan_select_all } from "../../../api/sf_hinhthucthanhtoan";
import { select_by_hocsinh as uf_hocsinh_congno_select_by_hocsinh } from "../../../api/uf_hocsinh_congno";
import {
    select_by_id as uf_donhang_select_by_id,
    pay
} from "../../../api/uf_donhang";
import {
    select_by_donhang as uf_donhang_hinhthucthanhtoan_select_by_donhang
} from "../../../api/uf_donhang_hinhthucthanhtoan";
import { StoreContext } from "../../../contexts/store";
import { status_enum } from "../detail_modal/reducer";
import "./modal_hinhthuc.css";
import { action_type, ini_state, reducer } from "./reducer";
export default ({ is_show_modal, uf_donhang_id, onCancel, onSuccess }) => {
    const [state, dispatch] = useReducer(reducer, ini_state);
    const { set_notification } = useContext(StoreContext);
    const {
        sf_hinhthucthanhtoans,
        uf_donhang_hinhthucthanhtoans,
        uf_donhang,
        uf_hocsinh_congno,
        status
    } = state;
    const tong_tien_da_nop = uf_donhang_hinhthucthanhtoans.map(x => x.so_tien).reduce((a, b) => a + b, 0);

    useEffect(() => {
        (async () => {
            const res = await sf_hinhthucthanhtoan_select_all();
            if (res.is_success) {
                dispatch({ type: action_type.SET_DM_HINHTHUCs, payload: res.data });
            } else {
                dispatch({ type: action_type.SET_DM_HINHTHUCs, payload: [] });
            }
        })();
    }, []);
    useEffect(() => {

        if (uf_donhang_id > 0 && is_show_modal) {
            (async () => {
                const res = await uf_donhang_select_by_id(uf_donhang_id);
                if (res.is_success) {
                    dispatch({ type: action_type.SET_DONHANG, payload: res.data });
                } else {
                    dispatch({ type: action_type.SET_DONHANG, payload: {} });
                }
            })();
        } else {
            dispatch({ type: action_type.SET_DONHANG, payload: {} });
        }
    }, [uf_donhang_id, is_show_modal]);
    useEffect(() => {
        if (uf_donhang.id > 0) {
            (async () => {
                const res = await uf_donhang_hinhthucthanhtoan_select_by_donhang(uf_donhang.id);
                if (res.is_success) {
                    dispatch({ type: action_type.END_SELECT_HINHTHUCTHANHTOANS, payload: res.data })
                } else {
                    dispatch({ type: action_type.END_SELECT_HINHTHUCTHANHTOANS, payload: [] })
                }
            })();
            reload_congno();
        }
    }, [uf_donhang]);
    const reload_congno = async () => {
        const res = await uf_hocsinh_congno_select_by_hocsinh(uf_donhang.ts_hocsinh_id);
        if (res.is_success) {
            dispatch({ type: action_type.SET_HOCSINH_CONGNO, payload: res.data });
        }
    }
    const handleSaveChanges = async () => {
        if (tong_tien_da_nop < uf_donhang.thanh_tien) {
            set_notification({ type: "warning", text: "Vui lòng nộp đủ số tiền của đơn hàng" });
        }
        dispatch({ type: action_type.BEGIN_SAVING })
        const res = await pay({
            uf_donhang_id: uf_donhang.id,
            uf_donhang_hinhthucthanhtoans: uf_donhang_hinhthucthanhtoans
        })
        dispatch({ type: action_type.END_SAVING })
        if (res.is_success) {
            set_notification({ type: "success", text: "Cập nhật thành công" });
            onSuccess(res.data);
        } else {
            set_notification({ type: "error", text: "Có lỗi" });
        }
    }
    const ListHinhThuc = () => {
        return sf_hinhthucthanhtoans.map(x => {
            return HinhThuc(x);
        });
    }
    const traTienThua = () => {
        const tien_thua = tong_tien_da_nop - uf_donhang.thanh_tien;
        const data = uf_donhang_hinhthucthanhtoans.map(x => {
            if (x.sf_hinhthucthanhtoan_id == 1) {
                return { ...x, so_tien: x.so_tien - tien_thua }
            } else {
                return x;
            }
        })
        dispatch({ type: action_type.END_SELECT_HINHTHUCTHANHTOANS, payload: data })
    }
    const HinhThuc = (data) => {
        const uf_donhang_hinhthucthanhtoan = uf_donhang_hinhthucthanhtoans.find(x => x.sf_hinhthucthanhtoan_id == data.id);
        return <Card fluid className="card-hinhthuc">
            <Card.Content>
                <Card.Description>
                    <div className="row">
                        <div className="">
                            <label className="p-chip p-chip-big">
                                <input type="checkbox" name="rd" checked={uf_donhang_hinhthucthanhtoan && uf_donhang_hinhthucthanhtoan.so_tien > 0} />
                                <span className="pay_chose">
                                    <Image src={"../images/" + data.img} />
                                    {data.short_text}</span>
                            </label>
                        </div>
                        <div className="">
                            <table key={data.id}>
                                <tr key={data.id + "1"}>
                                    <td key={data.id + "11"}>
                                        {/* <label>Số tiền đã nộp</label> */}
                                        <NumberBox
                                            value={uf_donhang_hinhthucthanhtoan ? uf_donhang_hinhthucthanhtoan.so_tien : null}
                                            min={0}
                                            stylingMode="outlined"
                                            format=",##0 VNĐ"
                                            className="font-size-30"
                                            width={300}
                                            placeholder={`Nhập số tiền đã nộp bằng ${data.hinh_thuc_thanh_toan}`}
                                            onValueChanged={(e) => {
                                                if (uf_donhang_hinhthucthanhtoan) {
                                                    const new_data = uf_donhang_hinhthucthanhtoans.map(x => {
                                                        if (x.sf_hinhthucthanhtoan_id == data.id) {
                                                            return { ...x, so_tien: e.value }
                                                        } else {
                                                            return x;
                                                        }
                                                    });
                                                    dispatch({ type: action_type.END_SELECT_HINHTHUCTHANHTOANS, payload: new_data });
                                                } else {
                                                    let new_data = uf_donhang_hinhthucthanhtoans;
                                                    new_data.push({
                                                        sf_hinhthucthanhtoan_id: data.id,
                                                        so_tien: e.value
                                                    });
                                                    dispatch({ type: action_type.END_SELECT_HINHTHUCTHANHTOANS, payload: new_data });
                                                }

                                            }}
                                        />
                                    </td>

                                    {data.is_have_ma_chuan_chi &&
                                        <td key={data.id + "12"}>
                                            {/* <label>Mã chuẩn chi</label> */}
                                            <TextBox
                                                value={uf_donhang_hinhthucthanhtoan ? uf_donhang_hinhthucthanhtoan.ma_chuan_chi : null}
                                                stylingMode="outlined"
                                                className="font-size-30"
                                                width={200}
                                                placeholder="Nhập mã chuẩn chi"
                                                onValueChanged={(e) => {
                                                    if (uf_donhang_hinhthucthanhtoan) {
                                                        const new_data = uf_donhang_hinhthucthanhtoans.map(x => {
                                                            if (x.sf_hinhthucthanhtoan_id == data.id) {
                                                                return { ...x, ma_chuan_chi: e.value }
                                                            } else {
                                                                return x;
                                                            }
                                                        });
                                                        dispatch({ type: action_type.END_SELECT_HINHTHUCTHANHTOANS, payload: new_data });
                                                    } else {
                                                        const new_data = uf_donhang_hinhthucthanhtoans.map(x => x).push({
                                                            sf_hinhthucthanhtoan_id: data.id,
                                                            ma_chuan_chi: e.value
                                                        });
                                                        dispatch({ type: action_type.END_SELECT_HINHTHUCTHANHTOANS, payload: new_data });
                                                    }
                                                }}
                                            />
                                        </td>
                                    }
                                </tr>
                                <tr key={data.id + "2"}>
                                    <td className="padding-top-10">
                                        <label className="p-form-checkbox-cont margin-left-0">
                                            <input type="checkbox" name="example"
                                                checked={uf_donhang_hinhthucthanhtoan && uf_donhang_hinhthucthanhtoan.so_tien == uf_donhang.thanh_tien} onChange={(event) => {
                                                    if (event.target.checked) {
                                                        let new_data = [];
                                                        if (uf_donhang_hinhthucthanhtoan) {
                                                            new_data = uf_donhang_hinhthucthanhtoans.map(x => {
                                                                if (x.sf_hinhthucthanhtoan_id == data.id) {
                                                                    return { ...x, so_tien: uf_donhang.thanh_tien }
                                                                } else {
                                                                    return { ...x, so_tien: 0 }
                                                                }
                                                            });
                                                        } else {
                                                            new_data.push({
                                                                sf_hinhthucthanhtoan_id: data.id,
                                                                so_tien: uf_donhang.thanh_tien
                                                            })
                                                        }
                                                        debugger
                                                        dispatch({ type: action_type.END_SELECT_HINHTHUCTHANHTOANS, payload: new_data });
                                                    }
                                                    else {
                                                        const new_data = uf_donhang_hinhthucthanhtoans.map(x => {
                                                            return { ...x, so_tien: 0 }
                                                        });
                                                        debugger
                                                        dispatch({ type: action_type.END_SELECT_HINHTHUCTHANHTOANS, payload: new_data });
                                                    }
                                                }} />
                                            <span> </span>
                                            Nộp đủ tiền bằng {data.hinh_thuc_thanh_toan}
                                        </label>
                                    </td>
                                </tr>
                            </table>


                        </div>
                    </div>
                </Card.Description>
            </Card.Content>
        </Card>
    }
    return (
        <React.Fragment>
            <Modal open={is_show_modal} size="small" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                height={window.innerHeight - 250}
                className="no-opactiy"
                closeOnDimmerClick={false}>

                <Modal.Header>
                    {uf_donhang.ma_don_hang}
                    <p><i className="help-text">*Sau khi hoàn thành thu tiền, bạn sẽ không thể chỉnh sửa nội dung đơn hàng.</i></p>
                </Modal.Header>
                <Modal.Content>
                    <div className="modal_hinhthuc_content">
                        <div className="row">
                            <div className="col-md-6">
                                {uf_donhang && uf_donhang.thanh_tien &&
                                    <table>
                                        <tr>
                                            <td>
                                                Số tiền phải nộp:
                                            </td>
                                            <td className="text-align-right">
                                                <b>{uf_donhang.thanh_tien.toLocaleString()}</b> VNĐ
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Thực nộp:
                                            </td>
                                            <td className="text-align-right">
                                                <b className="text-success">{tong_tien_da_nop.toLocaleString()}</b> VNĐ
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Chênh lệch:
                                            </td>
                                            <td className="text-align-right">
                                                <b className="text-danger">{(tong_tien_da_nop - uf_donhang.thanh_tien).toLocaleString()}</b> VNĐ
                                                {(tong_tien_da_nop > uf_donhang.thanh_tien) &&
                                                    <a className="margin-left-5" onClick={traTienThua}>Trả lại PH</a>}
                                            </td>
                                        </tr>
                                    </table>
                                }
                            </div>
                            <div className="col-md-6 text-align-right">
                                {(uf_hocsinh_congno && uf_hocsinh_congno.thua_thieu != 0) &&
                                    <b>Số tiền học sinh thừa/thiếu từ các đơn trước:
                                        <br></br> <b className="text-danger">
                                            {uf_hocsinh_congno.thua_thieu > 0 ? "Thừa " : "Thiếu: "} {uf_hocsinh_congno.thua_thieu.toLocaleString()} VNĐ</b></b>
                                }
                            </div>
                            {/* <div className="col-md-12">
                                <h5>Tổng tiền đã chọn: <b>{uf_donhang_hinhthucthanhtoans.map(x => x.so_tien).reduce((a, b) => a + b, 0).toLocaleString()}</b> VNĐ</h5>
                            </div> */}
                            <div className="col-md-12">
                                <ListHinhThuc />
                            </div>
                        </div>
                    </div>

                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                        <button disabled={status == status_enum.is_saving} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                            {"Cập nhật"}{status == status_enum.is_saving && <Loader active inline size="mini" />}
                        </button>

                    </div>
                </Modal.Actions>
            </Modal >

        </React.Fragment >
    );
}