import React, { useEffect, useRef, useReducer, useState, useContext, useMemo } from "react";
import { Confirm, Transition } from "semantic-ui-react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import moment from "moment";
import DataGrid, {
  Sorting,
  Pager,
  Paging,
  Selection,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Column,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { DateBox, TextBox } from "devextreme-react";
import { StoreContext } from "../../contexts/store";
import {
  summary,
  update_giao_hang,
  update_huy_phieutreo,
} from "../../api/uf_donhang";
import {
  select_all as sf_hinhthucthanhtoan_select_all
} from "../../api/sf_hinhthucthanhtoan";
import CbxTrangThaiDonHang from "../../components/editor/cmb_trangthaidonhang";
import ComboboxKho from "../../components/editor/cmb_kho";
import { PrimaryButton, DefaultButton, DangerButton } from "../../components/editor/button";
import { status_enum, action_type, ini_state, reducer } from "./reducer";
import HuyDonHangModal from "./modal/huy_donhang_modal";

export default () => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const grid = useRef(null);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const [canUpdateDaGiaoHang, setCanUpdateDaGiaoHang] = useState(false);
  const [canUpdateHuyDonHang, setCanUpdateHuyDonHang] = useState(false);
  const [canUpdateHuyPhieuTreo, setCanUpdateHuyPhieuTreo] = useState(false);

  const {
    status,
    orders,
    searchModel,
    selectedRowKeys,
    is_show_confirm_cap_nhat_giao_hang,
    is_show_confirm_huy_donhang,
    is_show_confirm_huy_phieu_treo,
    sf_hinhthucthanhtoans,
    uf_donhang_hinhthucthanhtoans
  } = state;
  useEffect(() => {
    if (status === status_enum.is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [status]);
  useEffect(() => {
    searchOrders();
    handleReloadHinhThucThanhToans();
  }, []);
  useEffect(() => {
    let data = { ...searchModel }
    if (data.tu_ngay) {
      data.tu_ngay_text = moment(data.tu_ngay).format("DD/MM/YYYY")
    } else {
      data.tu_ngay_text = null;
    }
    if (data.den_ngay) {
      data.den_ngay_text = moment(data.den_ngay).format("DD/MM/YYYY")
    } else {
      data.den_ngay_text = null;
    }
    dispatch({ type: action_type.SET_SEARCH_MODEL, payload: data })
  }, [searchModel.tu_ngay, searchModel.den_ngay])
  const dataSouce = useMemo(() => {
    const source = orders.map(uf_donhang => {
      const uf_donhang_hinhthucthanhtoans_order = uf_donhang_hinhthucthanhtoans.filter(x => x.uf_donhang_id == uf_donhang.id);
      let item = { ...uf_donhang };
      sf_hinhthucthanhtoans.forEach(sf_hinhthucthanhtoan => {
        const uf_donhang_hinhthucthanhtoan = uf_donhang_hinhthucthanhtoans_order.find(x => x.sf_hinhthucthanhtoan_id == sf_hinhthucthanhtoan.id);
        if (uf_donhang_hinhthucthanhtoan) {
          item[`T_${sf_hinhthucthanhtoan.id}`] = uf_donhang_hinhthucthanhtoan.so_tien
        }
      })
      return item;
    });
    // console.log({
    //   source,
    //   uf_donhang_hinhthucthanhtoans,
    //   sf_hinhthucthanhtoans
    // })
    return source;
  }, [orders, sf_hinhthucthanhtoans, uf_donhang_hinhthucthanhtoans]);
  const handleReloadHinhThucThanhToans = async () => {
    const res = await sf_hinhthucthanhtoan_select_all();
    if (res.is_success) {
      dispatch({ type: action_type.SET_HINHTHUCTHANHTOANS, payload: res.data });

    }
  }
  const searchOrders = async () => {
    dispatch({
      type: action_type.BEGIN_SEARCH_ORDERS,
    });
    const res = await summary(searchModel);
    if (res.is_success) {
      dispatch({
        type: action_type.END_SEARCH_ORDERS,
        payload: {
          uf_donhangs: res.data.uf_donhangs.map(x => {
            return { ...x, thua_thieu: x.thuc_nop - x.phai_nop }
          }),
          uf_donhang_hinhthucthanhtoans: res.data.uf_donhang_hinhthucthanhtoans
        },
      });
    } else {
      dispatch({ type: action_type.END_SEARCH_ORDERS, payload: [] });
    }
  };

  const onSubmitSearchForm = async (event) => {
    event.preventDefault();
    await searchOrders();
  };

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    dispatch({
      type: action_type.MULTI_SELECT_CHANGE,
      payload: selectedRowKeys,
    });
    setCanUpdateDaGiaoHang(
      selectedRowsData.filter((r) => r.uf_trangthaidonhang_id === 3).length === selectedRowsData.length
    );

    setCanUpdateHuyDonHang(
      selectedRowsData.filter((r) => r.uf_trangthaidonhang_id == 2
        || r.uf_trangthaidonhang_id == 3
        || r.uf_trangthaidonhang_id == 4).length === selectedRowsData.length
    );

    setCanUpdateHuyPhieuTreo(
      selectedRowsData.filter((r) => r.uf_trangthaidonhang_id === 1).length === selectedRowsData.length
    );
  };
  const changeStatusGiaoHang = () => {
    dispatch({
      type: action_type.SHOW_CONFIRM_GIAO_HANG,
    });
  };
  const changeStatusHuyPhieuTreo = () => {
    dispatch({
      type: action_type.SHOW_CONFIRM_HUY_PHIEU_TREO,
    });
  };
  const changeStatusHuyDonHang = () => {
    dispatch({
      type: action_type.SHOW_CONFIRM_HUY_DONHANG,
    });
  };

  const btnCancel_cap_nhat_giao_hang = () => {
    dispatch({
      type: action_type.CLOSE_CONFIRM_GIAO_HANG,
    });
  };

  const on_cap_nhat_giao_hang = async () => {
    dispatch({
      type: action_type.BEGIN_UPDATE_ORDERS,
    });
    const res = await update_giao_hang(selectedRowKeys);
    if (res.is_success) {
      dispatch({
        type: action_type.END_UPDATE_ORDERS,
      });

      dispatch({
        type: action_type.CLOSE_CONFIRM_GIAO_HANG,
      });

      searchOrders();
    } else {
      dispatch({ type: action_type.END_UPDATE_ORDERS });
    }
  };
  const btnCancel_cap_nhat_huy_phieu_treo = () => {
    dispatch({
      type: action_type.CLOSE_CONFIRM_HUY_PHIEU_TREO,
    });
  };
  const btnCancel_huy_donhang = () => {
    dispatch({
      type: action_type.CLOSE_CONFIRM_HUY_DONHANG,
    });
  };

  const on_cap_nhat_huy_phieu_treo = async () => {
    dispatch({
      type: action_type.BEGIN_UPDATE_ORDERS,
    });
    const res = await update_huy_phieutreo(selectedRowKeys);
    if (res.is_success) {
      dispatch({
        type: action_type.END_UPDATE_ORDERS,
      });
      dispatch({
        type: action_type.CLOSE_CONFIRM_HUY_PHIEU_TREO,
      });
      searchOrders();
    } else {
      dispatch({ type: action_type.END_UPDATE_ORDERS });
    }
  };
  const on_huy_donhang_success = async () => {
    dispatch({
      type: action_type.CLOSE_CONFIRM_HUY_DONHANG,
    });
    searchOrders();
  };
  return (
    <React.Fragment>
      <Transition
        animation="fade right"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <div className="row">


            <div className="col-md-12 padding-top-1rem padding-left-2rem">
              <form onSubmit={onSubmitSearchForm}>
                <Toolbar className="bg-background">
                  <Item
                    location="before"
                    locateInMenu="auto"
                    render={() => {
                      return <h3>Danh sách đơn hàng</h3>;
                    }}
                  />
                  <Item
                    location="after"
                    locateInMenu="never"
                    render={() => {
                      return (
                        <CbxTrangThaiDonHang
                          value={searchModel.uf_trangthaidonhang_ids}
                          onValueChanged={(value) => {
                            dispatch({
                              type: action_type.SET_TRANGTHAIDONHANG,
                              payload: value,
                            });
                          }}
                        />
                      );
                    }}
                  />
                  <Item
                    location="after"
                    locateInMenu="never"
                    render={() => {
                      return (
                        <ComboboxKho
                          value={searchModel.uf_kho_id}
                          onValueChanged={(value) => {
                            dispatch({
                              type: action_type.SET_KHO,
                              payload: value,
                            });
                          }}
                        />
                      );
                    }}
                  />
                  <Item
                    location="after"
                    locateInMenu="never"
                    render={() => {
                      return (
                        <DateBox
                          displayFormat="dd/MM/yyyy"
                          showClearButton={false}
                          onValueChanged={(e) => {


                            dispatch({
                              type: action_type.SET_TU_NGAY,
                              payload: e.value,
                            });
                          }}
                          placeholder="Từ ngày"
                          stylingMode="outlined"
                          value={searchModel.tu_ngay}
                          max={searchModel.den_ngay}
                          type="date"
                          displayFormat={"'Từ': dd/MM/yy"}
                        />
                      );
                    }}
                  />
                  <Item
                    location="after"
                    locateInMenu="never"
                    render={() => {
                      return (
                        <DateBox
                          placeholder="Đến ngày"
                          displayFormat="dd/MM/yyyy"
                          showClearButton={false}
                          onValueChanged={(e) => {
                            dispatch({
                              type: action_type.SET_DEN_NGAY,
                              payload: e.value,
                            });
                          }}
                          value={searchModel.den_ngay}
                          min={searchModel.tu_ngay}
                          type="date"
                          stylingMode="outlined"
                          displayFormat={"'Đến': dd/MM/yy"}
                        />
                      );
                    }}
                  />
                  <Item
                    location="after"
                    locateInMenu="never"
                    render={() => {
                      return (
                        <TextBox
                          stylingMode="outlined"
                          placeholder="Tên hoặc Mã HS"
                          onValueChanged={(e) => {
                            dispatch({
                              type: action_type.SET_HOCSINH,
                              payload: e.value,
                            });
                          }}
                        />
                      );
                    }}
                  />
                  <Item
                    location="after"
                    locateInMenu="never"
                    render={() => {
                      return (
                        <DefaultButton
                          icon="fas fa-search"
                          type="submit"
                          text="Tìm kiếm"
                        />
                      );
                    }}
                  />

                </Toolbar>
              </form>
            </div>
            <div className="col-md-12  padding-left-2rem">
              <Toolbar className="bg-background">
                {can_accessed("api/uf_donhang/update_giaohang") &&
                  <Item
                    location="after"
                    locateInMenu="never"
                    render={() => {
                      return (
                        <PrimaryButton
                          icon="fas fa-truck"
                          disabled={
                            selectedRowKeys.length === 0 || !canUpdateDaGiaoHang
                          }
                          onClick={changeStatusGiaoHang}
                          text="Cập nhật đã giao hàng"
                          className="margin-0"
                        />
                      );
                    }}
                  />
                }
                {can_accessed("api/uf_donhang/update_huy_phieutreo") &&
                  <Item
                    location="after"
                    locateInMenu="never"
                    render={() => {
                      return (
                        <DangerButton
                          icon="fas fa-ban"
                          disabled={
                            selectedRowKeys.length === 0 || !canUpdateHuyPhieuTreo
                          }
                          onClick={changeStatusHuyPhieuTreo}
                          text="Hủy phiếu treo"
                          className="margin-0"
                        />
                      );
                    }}
                  />
                }
                {can_accessed("api/uf_donhang/update_huy_donhang") &&
                  <Item
                    location="after"
                    locateInMenu="never"
                    render={() => {
                      return (
                        <DangerButton
                          icon="fas fa-window-close"
                          disabled={
                            selectedRowKeys.length === 0 || !canUpdateHuyDonHang
                          }
                          onClick={changeStatusHuyDonHang}
                          text="Hủy đơn hàng"
                          className="margin-0"
                        />
                      );
                    }}
                  />
                }
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <DefaultButton
                        text={"Export"}
                        icon="fas fa-file-export"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      />
                    );
                  }}
                />
              </Toolbar>
            </div>
          </div>
          <DataGrid
            ref={grid}
            keyExpr="id"
            dataSource={dataSouce}
            columnHidingEnabled={false}
            columnAutoWidth={true}
            showBorders={true}
            showColumnLines={false}
            showRowLines={true}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            hoverStateEnabled={true}
            focusedRowEnabled={false}
            height={window.innerHeight - 200}
            selectedRowKeys={selectedRowKeys}
            onSelectionChanged={onSelectionChanged}
            className="table-noborder-left"
            noDataText="Không có dữ liệu"
          >
            <Selection mode="multiple" showCheckBoxesMode="always" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={50} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <HeaderFilter visible={true} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50, 200, 500]}
              infoText="Trang {0} /{1} ({2} dòng)"
              showInfo={true}
            />
            <Column caption="Đơn hàng" alignment="center" fixed>
              <Column
                dataField="trangthaidonhang"
                caption="Trạng thái"
                allowFiltering={false}
                fixed={true}
              />
              <Column
                dataField="ma_don_hang"
                caption="Mã đơn hàng"
                fixed={true}
              />
              <Column
                dataField="ma_don_hang_goc"
                allowFiltering={false}
                caption="Mã đơn hàng gốc"
              />
              <Column
                dataField="created_time"
                fixed={true}
                allowFiltering={false}
                caption="Ngày tạo"
                dataType="date"
                format="dd/MM/yyyy HH:mm"
              />

            </Column>

            <Column caption="Số tiền" alignment="center">
              {/* <Column
                dataField="thanh_tien"
                caption="Tổng tiền"
                minWidth={150}
                allowFiltering={false}
                format=",##0"
              /> */}
              <Column
                dataField="phai_nop"
                caption="Phải nộp"
                minWidth={150}
                allowFiltering={false}
                format=",##0"
                cellRender={(cell) => {
                  return <p className="font-mono">{cell.data.phai_nop.toLocaleString()}</p>
                }}
              />
              <Column alignment={"center"}
                caption="Đã nộp"
              >

                <Column
                  dataField="thuc_nop"
                  caption="Đã thu"
                  minWidth={150}
                  allowFiltering={false}
                  format=",##0"
                  cellRender={(cell) => {
                    return <p className="font-mono">{cell.data.thuc_nop.toLocaleString()}</p>
                  }}
                />
                {sf_hinhthucthanhtoans.map(x => {
                  return <Column
                    key={x.id}
                    dataField={`T_${x.id}`}
                    caption={x.hinh_thuc_thanh_toan}
                    minWidth={150}
                    allowFiltering={false}
                    format=",##0"
                    cellRender={(cell) => {
                      if (cell.data[`T_${x.id}`]) {
                        return <p className="font-mono">{cell.data[`T_${x.id}`].toLocaleString()}</p>
                      } else {
                        return <p className="font-mono">-</p>
                      }

                    }}
                  />
                })}
              </Column>

              <Column
                dataField="thua_thieu"
                caption="Thừa/thiếu"
                minWidth={150}
                allowFiltering={false}
                format=",##0"
                cellRender={(cell) => {
                  return <p className="font-mono">{cell.data.thua_thieu.toLocaleString()}</p>
                }}
              />
              <Column dataField="nguoi_thu_tien" caption="Người thu tiền" />
              <Column
                dataField="ngay_thu_tien"
                caption="Ngày thu tiền"
                allowFiltering={false}
                dataType="date"
                format="dd/MM/yyyy HH:mm"
              />
            </Column>
            <Column
              dataField="ngay_hoan_thanh"
              caption="Ngày hoàn thành"
              allowFiltering={false}
              dataType="date"
              format="dd/MM/yyyy HH:mm"
            />
            <Column caption="Thông tin đơn hàng" alignment="center">
              <Column
                dataField="nguoi_tao"
                caption="Người tạo"
                allowFiltering={false}
              />
              <Column
                dataField="nguoi_huy"
                allowFiltering={false}
                caption="Người hủy đơn"
              />
              <Column
                dataField="ngay_huy"
                caption="Ngày hủy đơn"
                allowFiltering={false}
                dataType="date"
                format="dd/MM/yyyy HH:mm"
              />

              <Column
                dataField="ly_do_huy"
                allowFiltering={false}
                caption="Lý do hủy đơn"
              />
            </Column>
            <Column caption="Thông tin học sinh" alignment="center">
              <Column dataField="ma_hocsinh" caption="Mã HS" />
              <Column dataField="ten_hocsinh" caption="Tên HS" />
              <Column dataField="ten_truong" caption="Cơ sở" />
              <Column dataField="ten_he" caption="Cấp học" />
              <Column dataField="ten_khoi" caption="Khối" />
              <Column dataField="lop_dang_hoc" caption="Lớp" />
              <Column dataField="dia_chi_tt" caption="Địa chỉ" />
              <Column dataField="dien_thoai_lien_he" caption="Điện thoại LH" />
              <Column dataField="ho_ten_lien_he" caption="Tên LH" />
            </Column>


            <Summary>
              {/* <TotalItem
                column="thanh_tien"
                summaryType="sum"
                valueFormat=",##0"
                alignment="right"
                displayFormat="Tổng: {0}"
              /> */}
              <TotalItem
                column="phai_nop"
                summaryType="sum"
                valueFormat=",##0"
                alignment="right"
                displayFormat="Phải nộp: {0}"
                cssClass="font-mono"

              />
              <TotalItem
                column="thuc_nop"
                summaryType="sum"
                valueFormat=",##0"
                alignment="right"
                displayFormat="Đã nộp: {0}"
                cssClass="font-mono"
              />
              {sf_hinhthucthanhtoans.map(x => {
                return <TotalItem
                  column={`T_${x.id}`}
                  summaryType="sum"
                  valueFormat=",##0"
                  alignment="right"
                  displayFormat="SUM: {0}"
                  cssClass="font-mono"
                />
              })}
              <TotalItem
                column="thua_thieu"
                summaryType="sum"
                valueFormat=",##0"
                alignment="right"
                displayFormat="Thừa/thiếu: {0}"
                cssClass="font-mono"
              />
            </Summary>
          </DataGrid>
        </div>
      </Transition>

      <Confirm
        open={is_show_confirm_cap_nhat_giao_hang && selectedRowKeys.length > 0}
        dimmer={"blurring"}
        header="Lưu ý"
        content={`Bạn có chắc chắn muốn cập nhật trạng thái đã giao hàng của ${selectedRowKeys.length > 1 ? selectedRowKeys.length : ""
          } đơn hàng này ?`}
        size="mini"
        cancelButton={() => {
          return (
            <DefaultButton text="Đóng" onClick={btnCancel_cap_nhat_giao_hang} />
          );
        }}
        confirmButton={() => {
          return (
            <PrimaryButton
              text={`Cập nhật`}
              isLoading={status === status_enum.is_saving}
              onClick={on_cap_nhat_giao_hang}
            />
          );
        }}
      />
      <Confirm
        open={is_show_confirm_huy_phieu_treo && selectedRowKeys.length > 0}
        dimmer={"blurring"}
        header="Lưu ý"
        content={`Bạn có chắc chắn muốn cập nhật trạng thái hủy phiếu treo của ${selectedRowKeys.length > 1 ? selectedRowKeys.length : ""
          } đơn hàng này ?`}
        size="mini"
        cancelButton={() => {
          return (
            <DefaultButton
              text="Đóng"
              onClick={btnCancel_cap_nhat_huy_phieu_treo}
            />
          );
        }}
        confirmButton={() => {
          return (
            <PrimaryButton
              text={`Cập nhật`}
              isLoading={status === status_enum.is_saving}
              onClick={on_cap_nhat_huy_phieu_treo}
            />
          );
        }}
      />
      {is_show_confirm_huy_donhang && selectedRowKeys.length > 0 && (
        <HuyDonHangModal
          onCancel={btnCancel_huy_donhang}
          selectedRowKeys={selectedRowKeys}
          onSuccess={on_huy_donhang_success}
        />
      )}
    </React.Fragment>
  );
};
