import moment from "moment";

export const status_enum = {
  is_loading: "is_loading",
  is_completed: "is_completed",
  is_saving: "is_saving",
};
export const ini_state = {
  status: status_enum,
  orders: [],
  searchModel: {
    tu_ngay: new Date(),
    den_ngay: new Date(),
    tu_ngay_text: moment(new Date()).format("DD/MM/YYYY"),
    den_ngay_text: moment(new Date()).format("DD/MM/YYYY")
  },
};
export const action_type = {
  BEGIN_SEARCH_ORDERS: "BEGIN_SEARCH_ORDERS",
  END_SEARCH_ORDERS: "END_SEARCH_ORDERS",

  SET_TRANGTHAIDONHANG: "SET_TRANGTHAIDONHANG",
  SET_KHO: "SET_KHO",
  SET_TU_NGAY: "SET_TU_NGAY",
  SET_DEN_NGAY: "SET_DEN_NGAY",
  SET_HOCSINH: "SET_HOCSINH",
  SET_SEARCH_MODEL: "SET_SEARCH_MODEL",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.BEGIN_SEARCH_ORDERS:
      return {
        ...state,
        status: status_enum.is_loading,
      };
    case action_type.END_SEARCH_ORDERS:
      return {
        ...state,
        status: status_enum.is_completed,
        orders: action.payload,
      };
    case action_type.SET_TRANGTHAIDONHANG:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          uf_trangthaidonhang_ids: action.payload,
        },
      };
    case action_type.SET_HOCSINH:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          hocsinh: action.payload,
        },
      };
    case action_type.SET_TU_NGAY:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          tu_ngay: action.payload,
        },
      };
    case action_type.SET_DEN_NGAY:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          den_ngay: action.payload,
        },
      };
    case action_type.SET_KHO:
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          uf_kho_id: action.payload,
        },
      };
    case action_type.SET_SEARCH_MODEL:
      return {
        ...state,
        searchModel: action.payload,
      };
    default:
      return state;
  }
};
