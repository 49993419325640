import DataGrid, {
  Column, FilterPanel, FilterRow,
  HeaderFilter, Lookup, Pager,
  Paging,
  Selection, Sorting
} from "devextreme-react/data-grid";
import Toolbar, { Item } from "devextreme-react/toolbar";
import React, {
  useCallback,
  useContext, useEffect, useReducer, useRef
} from "react";
import { Confirm, Transition } from "semantic-ui-react";
import { remove, select_all } from "../../api/uf_nhapkho";
import {
  DangerButton, DefaultButton, PrimaryButton
} from "../../components/editor/button";
import DropDownButton from 'devextreme-react/drop-down-button';
import { StoreContext } from "../../contexts/store";
import DetailModal from "./detail_modal/detail_modal";
import ImportModal from "./import_modal/import_modal";
import { action_type, ini_state, reducer, status_enum } from "./reducer";
import { select_all as uf_sanphamchitiet_select_alll } from "../../api/uf_sanphamchitiet";
import { select_all as uf_kho_select_all } from "../../api/uf_kho";


export default () => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const grid = useRef(null);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const {
    status,
    uf_nhapkhos,
    uf_nhapkho_selected,
    uf_sanphamchitiets,
    uf_khos,
    is_show_confirm,
    is_show_detail_modal,
    is_show_import_modal
  } = state;
  useEffect(() => {
    if (status === status_enum.is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [status]);
  useEffect(() => {
    (async () => {
      await reload_sanphamchitiets();
      await reload_khos();
      reload();
    })();

  }, []);

  const reload = useCallback(async () => {
    dispatch({ type: action_type.BEGIN_SELECT_NHAPKHOS });
    const res = await select_all();
    if (res.is_success) {
      dispatch({
        type: action_type.END_SELECT_NHAPKHOS,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.END_SELECT_NHAPKHOS, payload: [] });
    }
  }, []);
  const reload_sanphamchitiets = async () => {
    const res = await uf_sanphamchitiet_select_alll();
    if (res.is_success) {
      dispatch({
        type: action_type.SET_SANPHAMCHITIETS,
        payload: res.data.map(x => {
          return { ...x, ma_sp_chitiet: `[${x.ma_sp_chitiet}] ${x.ten_sp} ${x.size}` }
        }),
      });
    } else {
      dispatch({ type: action_type.SET_SANPHAMCHITIETS, payload: [] });
      set_notification({ type: "error", text: res.message });
    }
  };
  const reload_khos = async () => {
    const res = await uf_kho_select_all();
    if (res.is_success) {
      dispatch({
        type: action_type.SET_KHOS,
        payload: res.data,
      });
    } else {
      dispatch({ type: action_type.SET_KHOS, payload: [] });
      set_notification({ type: "error", text: res.message });
    }
  };
  const btnCancel_click = useCallback(() => {
    dispatch({ type: action_type.CLOSE_DETAIL_MODAL });

  }, []);
  const btnCancelImport_click = useCallback(() => {
    dispatch({ type: action_type.CLOSE_IMPORT_MODAL });

  }, []);
  const btnAdd_OnItemClick = (e) => {
    if (e.itemData.id == 1) {
      dispatch({ type: action_type.SHOW_DETAIL_MODAL, payload: { id: 0 } });
    }
    if (e.itemData.id == 2) {
      dispatch({ type: action_type.SHOW_IMPORT_MODAL });
    }
  }


  const btnEdit_click = (data) => {
    dispatch({ type: action_type.SHOW_DETAIL_MODAL, payload: data });
  };
  const btnDelete_click = (data) => {
    dispatch({ type: action_type.SHOW_CONFIRM, payload: data });
  };

  const btnCancel_delete_click = useCallback(() => {
    dispatch({ type: action_type.CLOSE_CONFIRM });
  }, []);

  const onSuccess = () => {
    dispatch({ type: action_type.CLOSE_DETAIL_MODAL });
    reload();
  };
  const onImportSuccess = () => {
    dispatch({ type: action_type.CLOSE_IMPORT_MODAL });
    reload();
  };
  const onDelete = async () => {
    dispatch({ type: action_type.BEGIN_SAVING });
    const res = await remove({
      id: uf_nhapkho_selected.id,
      ly_do_xoa: ""
    });
    if (res.is_success) {
      set_notification({ type: "success", text: "Cập nhật thành công" });
      reload();
      dispatch({ type: action_type.CLOSE_CONFIRM });
    } else {
      dispatch({ type: action_type.END_SAVING });
      set_notification({ type: "error", text: res.message });
    }
  };

  const canAccessUpdate = (data) => {
    return (
      can_accessed("api/uf_nhapkho/update") && (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            btnEdit_click(data.data);
          }}
        >
          <i aria-hidden="true" className="icon edit"></i>{" "}
        </span>
      )
    );
  };
  const canAccessDelete = (data) => {
    return (
      can_accessed("api/uf_nhapkho/delete") && (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            btnDelete_click(data.data);
          }}
        >
          <i aria-hidden="true" className="icon trash"></i>{" "}
        </span>
      )
    );
  };
  const commandCellRender = (data) => {
    return (
      <div>
        {canAccessUpdate(data)}
        {canAccessDelete(data)}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Transition
        animation="fade right"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <div className="row">
            <div className="col-md-12 padding-top-1rem padding-left-2rem">
              <Toolbar className="bg-background">
                <Item
                  location="before"
                  locateInMenu="never"
                  render={() => {
                    return <h3>Lịch sử nhập kho</h3>;
                  }}
                />
                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      can_accessed("api/uf_nhapkho/insert") && (
                        <React.Fragment>
                          {/* <PrimaryButton
                            text={"Thêm nhập kho"}
                            icon="fas fa-plus"
                            onClick={btnAdd_click}
                          /> */}
                          <DropDownButton
                            text="Thêm nhập kho"
                            className="PrimaryDropDownButton"
                            icon="fas fa-plus"
                            // dropDownOptions={{ width: 230 }}
                            displayExpr="name"
                            keyExpr="id"
                            items={[
                              { id: 1, name: 'Nhập thủ công', icon: 'user' },
                              { id: 2, name: 'Import từ excel', icon: 'xlsfile' },
                            ]}
                            onItemClick={btnAdd_OnItemClick}
                          />
                        </React.Fragment>
                      )
                    );
                  }}
                />

                <Item
                  location="after"
                  locateInMenu="never"
                  render={() => {
                    return (
                      <DefaultButton
                        text={"Export"}
                        icon="fas fa-file-export"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      />
                    );
                  }}
                />
              </Toolbar>
            </div>
          </div>
          <DataGrid
            ref={grid}
            keyExpr="id"
            dataSource={uf_nhapkhos}
            columnHidingEnabled={false}
            columnAutoWidth={true}
            showBorders={true}
            showColumnLines={false}
            showRowLines={false}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            hoverStateEnabled={true}
            focusedRowEnabled={false}
            height={window.innerHeight - 150}
            className="table-noborder-left"
            noDataText="Không có dữ liệu"
          >
            <Selection mode="single" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={50} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <HeaderFilter visible={true} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50, 200, 500]}
              infoText="Trang {0} /{1} ({2} dòng)"
              showInfo={true}
            />
            <Column caption="Thao tác" cellRender={commandCellRender} />
            <Column dataField="uf_sanphamchitiet_id" caption="Sản phẩm" >
              <Lookup displayExpr="ma_sp_chitiet" valueExpr="id" dataSource={uf_sanphamchitiets}></Lookup>
            </Column>
            <Column dataField="uf_kho_id" caption="Kho" >
              <Lookup displayExpr="ten_kho" valueExpr="id" dataSource={uf_khos}></Lookup>
            </Column>
            <Column dataField="ngay_nhap" caption="Ngày nhập" format="dd/MM/yyyy" dataType="date" />
            <Column dataField="so_luong" caption="Số lượng" format=",##0" />
            <Column dataField="ghi_chu" caption="Ghi chú" />
          </DataGrid>
        </div>
      </Transition>

      {is_show_detail_modal && (
        <DetailModal
          onCancel={btnCancel_click}
          onSuccess={onSuccess}
          uf_khos={uf_khos}
          uf_sanphamchitiets={uf_sanphamchitiets}
          uf_nhapkho_id={uf_nhapkho_selected ? uf_nhapkho_selected.id : 0}
        />
      )}
      {is_show_import_modal && (
        <ImportModal
          onCancel={btnCancelImport_click}
          onSuccess={onImportSuccess}
        />
      )}
      {is_show_confirm && (
        <Confirm
          open={true}
          dimmer={"blurring"}
          header="Lưu ý"
          content="Bạn có chắc chắn muốn xóa nhập kho này ?"
          size="mini"
          cancelButton={() => {
            return (
              <DefaultButton text="Đóng" onClick={btnCancel_delete_click} />
            );
          }}
          confirmButton={() => {
            return (
              <DangerButton
                icon="fas fa-trash"
                text="Xóa nhập kho"
                isLoading={status === status_enum.is_saving}
                onClick={onDelete}
              />
            );
          }}
        />
      )}
    </React.Fragment>
  );
};
